// src\components\songs\VideoEdit.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import ApiEndpoints from "components/Api";
import useMessageBox from "components/MessageBox";
import "./VideoEdit.css";

const VideoEdit = ({ initialVideos, songId }) => {
  const [videos, setVideos] = useState(initialVideos);
  const [newVideo, setNewVideo] = useState("");
  const [showMessage, MessageBoxComponent] = useMessageBox();

  useEffect(() => {
    setVideos(initialVideos);
  }, [initialVideos]);

  const handleVideoRemove = (videoLink) => {
    const newVideos = videos.filter((video) => video.link !== videoLink);
    setVideos(newVideos);
  };

  const handleVideoReset = (event) => {
    event.preventDefault();
    setVideos([...initialVideos]);
  };

  const handleVideoCreate = (videoLink) => {
    const newVideo = { id: 0, link: videoLink };
    if (!videos.find((video) => video.link === videoLink)) {
      const updatedVideos = [...videos, newVideo];
      setVideos(updatedVideos);
    }
  };

  const handleVideoInputSubmit = (event) => {
    event.preventDefault();
    if (newVideo.trim() !== "") {
      handleVideoCreate(newVideo.trim());
      setNewVideo("");
    }
  };

  const handleVideoSubmit = async (event) => {
    event.preventDefault();

    try {
      const token = localStorage.getItem("authToken");

      const videoRequests = videos.map((video) => {
        return { id: video.id, link: video.link };
      });

      const response = await axios.put(
        ApiEndpoints.SONG_EDIT_VIDEOS,
        { songId, videoRequests },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const updatedVideos = response.data.videos;
      setVideos(updatedVideos);

      showMessage("Zapisano zmiany w filmach pieśni.", "success");
    } catch (error) {
      showMessage(error.response.data.message, "error");
    }
  };

  return (
    <form className="video-edit-form" onSubmit={handleVideoSubmit}>
      <MessageBoxComponent />
      <div className="video-edit-container">
        <div className="video-edit-current-videos">
          <div>Istniejące filmy: </div>
          {videos.map((video) => (
            <div key={uuidv4()} className="video-edit-item">
              <a
                className="video-edit-link"
                target="blank"
                rel="noopener noreferrer"
                href={video.link}
              >
                {video.link}
              </a>
              <div className="video-edit-item-button">
                <button
                  className="primary-button"
                  onClick={() => handleVideoRemove(video.link)}
                >
                  Usuń
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="video-edit-input-container">
          <div className="video-edit-input-item">
            <div>Dodaj nowy film:</div>
            <input
              type="text"
              value={newVideo}
              onChange={(e) => setNewVideo(e.target.value)}
              placeholder="Nowy link wideo"
            />
            <button
              className="primary-button"
              onClick={handleVideoInputSubmit}
            >
              Dodaj
            </button>
          </div>
        </div>
      </div>
      <div className="video-edit-buttons-container">
      <div className="video-edit-button-item">
          <button
            className="primary-button"
            onClick={(e) => handleVideoReset(e)}
          >
            Resetuj filmy
          </button>
        </div>
        <div className="video-edit-button-item">
          <button className="primary-button">
            Zapisz filmy
          </button>
        </div>
        
      </div>
    </form>
  );
};

export default VideoEdit;
