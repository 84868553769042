// src/components/login/LoginPage.js
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "components/AuthContext";
import ApiEndpoints from "components/Api";
import AppPaths from "components/AppPaths";
import useLoading from "components/Loading";
import "./LoginPage.css";

const LoginPage = () => {
  const [showLoading, hideLoading, Loading] = useLoading();
  const { logIn } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError(null);
    if (username.trim() === "" || password.trim() === "") {
        return;
      }
      
try {
    showLoading();
    const response = await axios.post(ApiEndpoints.LOGIN, {
      username: username,
      password: password,
      rememberMe: rememberMe,
    });
    if (response.data.token) {
      logIn(response.data.token, response.data.expires);
      navigate(AppPaths.HOME);
    } else {
      alert("Invalid credentials");
    }
    hideLoading();
    } catch (error) {
        console.log(error.message);
        hideLoading();
    } 
  };

  return (
    <div className="login-page">
      <Loading />
      <div className="login-left">
        <div className="login-box-container">
          <div className="login-box">
            <form onSubmit={handleSubmit}>
              <div className="login-elements">
                <div className="login-logo" />
                <input
                  type="text"
                  placeholder="Nazwa użytkownika"
                  id="iUsername"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Hasło"
                  id="iPassword"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="login-checkbox">
                  <input
                    type="checkbox"
                    id="iRememberMe"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <label htmlFor="iRememberMe">Zapamiętaj mnie</label>
                </div>
                <button type="submit">Zaloguj</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="login-right" />
    </div>
  );
};

export default LoginPage;
