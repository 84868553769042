import React, { useEffect, useState, useRef } from "react";
import User from "components/User";
import UserStatuses from "components/UserStatuses";
import UserStatusButton from "components/UserStatusButton";
import AdminDayManagementOwnerButton from "components/Admin/AdminDayManagementOwnerButton";
import ExportAsPngToClipboardButton from "components/ExportAsPngToClipboardButton";
import DayManagementEditModeButton from "components/DayManagementEditModeButton";
import SingDaySongViewList from "components/SingDaySongViewList";
import SingDaySongEditList from "components/SingDaySongEditList";
import "./AdminDayManagement.css";
import SongToWordButton from "components/SongToWordButton";
import DayTexts from "components/DayTexts";
import ExportDayAsCeremoniaXml from "components/ExportDayAsCeremoniaXml";

const AdminDayManagement = ({ day: initialDay, refs }) => {
  const [day, setDay] = useState(initialDay);
  const [showUserStatuses, setShowUserStatuses] = useState(false);
  const targetRef = useRef(null);
  const [editMode, setEditMode] = useState(
    JSON.parse(localStorage.getItem(`singday-${day?.id}-editMode`) || "false")
  );

  const userId = localStorage.getItem("userId");
  const getUserStatus = () => {
    const userStatus = day.userStatuses.find(
      (userStatus) => userStatus.user.id.toString() === userId
    );
    if (userStatus) {
      return userStatus.status;
    } else {
      return "null";
    }
  };
  const actualUserStatus = getUserStatus();
  const updateSingDay = (newDay) => {
    setDay(newDay);
  };

  const formatedDate = new Date(day.date).toLocaleDateString("pl-PL");
  const formatedTime = new Date(day.date)
    .toLocaleTimeString("pl-PL")
    .slice(0, -3);

  const renderSongs = () => {
    if (!editMode) {
      return <SingDaySongViewList day={day} />;
    } else {
      return renderSongsEdit();
    }
  };
  const renderSongsEdit = () => {
    return <SingDaySongEditList day={day} updateSingDay={updateSingDay}/>;
  };
  
  const renderOrganizers = () => {
    if (day.owners && day.owners.length > 0) {
      return day.owners.map((owner) => (
        <User key={owner.id} user={owner} useCase="internal" />
      ));
    }
  };
  const renderOrganizersTitle = () => {
    if (day.owners && day.owners.length <= 1) {
      return "Pieśni układa: ";
    } else if (day.owners && day.owners.length > 1) {
      return "Pieśni układają: ";
    }
  };
  const renderPsalmist = () => {
    if (day.psalmist) {
      return (
        <User key={day.psalmist.id} user={day.psalmist} useCase="internal" />
      );
    }
  };
  const renderAdorationOwners = () => {
    if (day.adorationOwners && day.adorationOwners.length > 0) {
      return day.adorationOwners.map((owner) => (
        <User key={owner.id} user={owner} useCase="internal" />
      ));
    }
  };
  const renderAdorationOwnersTitle = () => {
    if (day.adorationOwners && day.adorationOwners.length > 1) {
      return "Adorację przygotowują: ";
    } else if (day.adorationOwners && day.adorationOwners.length <= 1) {
      return "Adorację przygotowuje: ";
    }
  };
  const isFirstSundayOfMonth = (date) => {
    return date.getDate() <= 7 && date.getDay() === 0; // Sunday is 0
  };

  const renderAdorationContainer = () => {
    if (
      (day.adorationOwners && day.adorationOwners.length > 0) ||
      isFirstSundayOfMonth(new Date(day.date))
    ) {
      return (
        <div className="admin-day-management-adoration-container">
          <div className="admin-day-management-adoration-header">
            {renderAdorationOwnersTitle()}
          </div>
          <div className="admin-day-management-adoration">
            {renderAdorationOwners()}
          </div>
          <div
            data-html2canvas-ignore="true"
            className="admin-day-management-adoration-buttons"
          >
            <AdminDayManagementOwnerButton
              day={day}
              updateSingDay={updateSingDay}
              buttonOwnerType="adorationOwner"
            />
          </div>
        </div>
      );
    }
  };

  const renderUserStatuses = () => {
    if (day.userStatuses && day.userStatuses.length > 0) {
      return <UserStatuses userStatuses={day.userStatuses} />;
    }
  };

  const checkEditButtonVisibility = () => {
    // let result = false;
    // if (day.owners && day.owners.length > 0)
    // result = day.owners.some((owner) => owner.id.toString() === userId);
    // if (day.adorationOwners && day.adorationOwners.length > 0)
    // result = (result || day.adorationOwners.some((owner) => owner.id.toString() === userId)); 
    // return result;
    return true;
  }

  useEffect(() => {
    if (day?.id > 0) {
      setShowUserStatuses(true);
    }
  }, [day]);
  return (
    <div
      id={"singday-" + day?.id}
      ref={el => {
        refs.current[day?.id] = el;
        targetRef.current = el;
      }}
      
      className={`day-management-container ${
        editMode
          ? "day-management-container-edit-mode-on"
          : "day-management-container-edit-mode-off"
      }`}
    > 
      <div className="admin-day-management-header">
        <DayManagementEditModeButton
          isVisible={checkEditButtonVisibility()}
          editMode={editMode}
          day={day}
          setEditMode={setEditMode}
        />
        <ExportAsPngToClipboardButton targetRef={targetRef} />
        <ExportDayAsCeremoniaXml day={day} />
        <SongToWordButton songs={day.songs} />
        <div className="admin-day-management-date">
          {formatedDate} {formatedTime}
        </div>
        <div className="admin-day-management-description">
          <a href={day.readingLink} target="_blank" rel="noopener noreferrer">
            {" "}
            {day.description}
          </a>
        </div>
        <div className="admin-day-management-location">{day.location}</div>
      </div>
      <div className="admin-day-management-owners-container">
        <div className="admin-day-management-organizers-container">
          <div className="admin-day-management-organizers-header">
            {renderOrganizersTitle()}
          </div>
          <div className="admin-day-management-organizers">{renderOrganizers()}</div>
          <div
            data-html2canvas-ignore="true"
            className="admin-day-management-organizers-buttons"
          >
            <AdminDayManagementOwnerButton
              day={day}
              updateSingDay={updateSingDay}
              buttonOwnerType="owner"
            />
          </div>
        </div>
        <div className="admin-day-management-psalmist-container">
          <div className="admin-day-management-psalmist-header">Psalm śpiewa: </div>
          <div className="admin-day-management-psalmist">{renderPsalmist()}</div>
          <div
            data-html2canvas-ignore="true"
            className="admin-day-management-psalmist-buttons"
          >
            <AdminDayManagementOwnerButton
              day={day}
              updateSingDay={updateSingDay}
              buttonOwnerType="psalmist"
            />
          </div>
        </div>
        {renderAdorationContainer()}
      </div>
      <div className="admin-day-management-songs">{renderSongs()}</div>
      <DayTexts acclamation={day.acclamation} psalmRefrain={day.psalmRefrain} />
      {showUserStatuses && (
        <div className="admin-day-management-people-container">
          <div className="admin-day-management-people-header">Obecności:</div>
          <div className="admin-day-management-people">{renderUserStatuses()}</div>
        </div>
      )}
      <div />
      {showUserStatuses && (
        <div data-html2canvas-ignore="true" className="admin-day-management-buttons">
          <div className="admin-day-management-button-userstatus-container">
            <UserStatusButton
              singDayId={day.id}
              updateSingDay={updateSingDay}
              actualUserStatus={actualUserStatus}
              buttonUserStatus="Obecność"
            />
            <UserStatusButton
              singDayId={day.id}
              updateSingDay={updateSingDay}
              actualUserStatus={actualUserStatus}
              buttonUserStatus="Spóźnienie"
            />
            <UserStatusButton
              singDayId={day.id}
              updateSingDay={updateSingDay}
              actualUserStatus={actualUserStatus}
              buttonUserStatus="Nieobecność"
            />
          </div>
        </div>
      )}

      <div />
    </div>
  );
};

export default AdminDayManagement;
