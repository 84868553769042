// src/components/Loading.js
import React, {useState} from 'react';
import './Loading.css';

const useLoading = () => {
  const [isVisible, setIsVisible] = useState(false);


  const showLoading = () => {

      setIsVisible(true);

  };
  const hideLoading = () => {

    setIsVisible(false);
  };
  const Loading = () => {
    return (
      isVisible && 
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  
    
  };
  return [showLoading, hideLoading, Loading];
}



export default useLoading;