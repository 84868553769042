// src/api.js
const ApiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const ApiEndpoints = {
  LOGIN: `${ApiBaseUrl}/Auth`,
  SONGS: `${ApiBaseUrl}/FullSongs`,
  SONG_DETAIL: (id) => `${ApiBaseUrl}/FullSongs/${id}`,
  SONG_ADD: `${ApiBaseUrl}/FullSongs/Add`,
  SONG_EDIT_TAGS: `${ApiBaseUrl}/FullSongs/EditTags`,
  SONG_EDIT_VIDEOS: `${ApiBaseUrl}/FullSongs/EditVideos`,
  SONG_EDIT_SCORE: `${ApiBaseUrl}/Scores`,
  SING_DAYS: `${ApiBaseUrl}/SingDays`,
  SING_DAYS_USER_STATUS: `${ApiBaseUrl}/SingDays/UserStatus`,
  SING_DAYS_OWNER_UPDATE: `${ApiBaseUrl}/SingDays/AddOwners`,
  ADMIN_SING_DAYS_OWNER_UPDATE: `${ApiBaseUrl}/SingDays/Admin/AddOwners`,
  SING_DAYS_REMOVE_ITEMS: `${ApiBaseUrl}/SingDays/RemoveItems`,
  ADMIN_SING_DAYS_REMOVE_ITEMS: `${ApiBaseUrl}/SingDays/Admin/RemoveItems`,
  SING_DAYS_PARTS: `${ApiBaseUrl}/SingDays/Parts`,
  SING_DAYS_ADD_SONG: `${ApiBaseUrl}/SingDays/AddSong`,
  ADMIN_USERS: `${ApiBaseUrl}/Admin/Users`,
  ADMIN_SING_DAYS_USER_STATUS: `${ApiBaseUrl}/SingDays/Admin/UserStatus`,
  SING_DAYS_XML: (id) => `${ApiBaseUrl}/SingDays/${id}/export`,
  SING_DAY_ADD_COMMENT: (id) => `${ApiBaseUrl}/SingDays/${id}/comments`,
  SING_DAY_DELETE_COMMENT: (sid,cid) => `${ApiBaseUrl}/SingDays/${sid}/comments/${cid}`,
};
