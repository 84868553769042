import React, {useEffect} from "react";
import DayManagement from "components/DayManagement";
import "./DayManagementList.css";

const DayManagementList = ({ singDays, refs }) => {
  return (
    <div className="day-management-list-container">
      {singDays.map((day) => (
        <DayManagement refs={refs} id={`dayid-${day.id}`} key={day.id} day={day}/>
      ))}
    </div>
  );
};

export default DayManagementList;
