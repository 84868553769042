const AppPaths = {
    HOME: '/',
    LOGIN: '/logowanie',
    SONG_LIST: '/listapiesni',
    SONG: '/piesn/:id',
    SONG_WITH_ID : (id) => `/piesn/${id}`,
    SONG_EDIT: '/edycjapiesni/:id',
    SONG_EDIT_WITH_ID: (id) => `/edycjapiesni/${id}`,
    SONG_ADD: '/dodawaniepiesni',
    SING_DAYS: '/dwudziestki',
    ADMIN_SING_DAYS: '/admin/dwudziestki',
    CHORD_TEST: '/chordtest',
    ADMIN_USER_PRESENCE: '/admin/obecnosci',
};

export default AppPaths;