import React, { useEffect, useState } from "react";
import axios from "axios";
import ApiEndpoints from "components/Api";
import moment from "moment";
import useLoading from "components/Loading";
import AdminUserStatusButton from "components/Admin/AdminUserStatusButton";
import "./AdminUserPage.css";
import { set } from "react-hook-form";

const AdminUserPage = () => {
  const [users, setUsers] = useState([]);
  const [singDays, setSingDays] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [selectedDayId, setSelectedDayId] = useState(0);
  const [showLoading, hideLoading, Loading] = useLoading();

  useEffect(() => {
    fetchUsersAndDays();
  }, []);

  const fetchUsersAndDays = async () => {
    const token = localStorage.getItem("authToken");
    const startDate = moment().startOf('day').format().slice(0, -6) + 'Z';
    const endDate = moment().add(1, 'months').endOf('day').format().slice(0, -6) + 'Z';

    showLoading();

    const [usersResponse, daysResponse] = await Promise.all([
      axios.get(ApiEndpoints.ADMIN_USERS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      axios.get(`${ApiEndpoints.SING_DAYS}?startDate=${startDate}&endDate=${endDate}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    ]);

    hideLoading();

    setUsers(usersResponse.data);
    setSingDays(daysResponse.data);
    setSelectedUserId(usersResponse.data[0].id);
    setSelectedDayId(daysResponse.data[0].id);
  };

  return (
    <div className="admin-user-page-container">
      <Loading />
      <div className="admin-user-dropdown-container">
        <select
          onChange={(e) => setSelectedUserId(e.target.value)}
        >
          {users.map((user) => (
            <option key={user.id} value={user.id}>
              {user.login}
            </option>
          ))}
        </select>

        <select
          onChange={(e) => setSelectedDayId(e.target.value)}
        >
          {singDays.map((day) => (
            <option key={day.id} value={day.id}>
              {day.date}
            </option>
          ))}
        </select>
      </div>

      <div className="admin-user-button-container">
        <AdminUserStatusButton buttonUserStatus="Obecność" userId={selectedUserId} singDayId={selectedDayId}/>
        <AdminUserStatusButton buttonUserStatus="Spóźnienie" userId={selectedUserId} singDayId={selectedDayId}/>
        <AdminUserStatusButton buttonUserStatus="Nieobecność" userId={selectedUserId} singDayId={selectedDayId}/>
      </div>
    </div>
  );
};

export default AdminUserPage;
