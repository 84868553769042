import React, { useState } from "react";
import User from "components/User";
import "./UserStatuses.css";

const UserStatuses = ({ userStatuses }) => {
  const statuses = ["Obecność", "Spóźnienie", null, "Nieobecność"];
  const [selectedStatus, setSelectedStatus] = useState("");

  const calculateCircleSize = (status) => {
    const numUsersWithStatus = userStatuses.filter(
      (userStatus) => userStatus.status === status
    ).length;
    const totalUsers = userStatuses.length;
    const size = 3 + 3 * (numUsersWithStatus / totalUsers);
    return `${size}em`;
  };

  const handleMouseEnter = (status) => {
    setSelectedStatus(status);
  };

  const handleMouseLeave = () => {
    setSelectedStatus("");
  };

  const handleClick = (status) => {
    if (selectedStatus === status) {
      setSelectedStatus("");
    } else {
      setSelectedStatus(status);
    }
  };

  return (
    <div className="user-statuses-container">
      {statuses.map((status, index) => (
        <div
          key={index}
          className="status-circle-container"
          onMouseEnter={() => handleMouseEnter(status)}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleClick(status)}
        >
          <div
            className={`status-circle ${
              status ? status.replace(/\s+/g, "").toLowerCase() : "noinfo"
            }`}
            style={{
              width: calculateCircleSize(status),
              height: calculateCircleSize(status),
            }}
          >
            {
              userStatuses.filter((userStatus) => userStatus.status === status)
                .length
            }
          </div>
          {selectedStatus === status &&
            userStatuses.filter((userStatus) => userStatus.status === status)
              .length > 0 && (
              <div className="user-statuses-user-list">
                <div className="user-statuses-status-name">{status || "Brak potwierdzenia"}:</div>
                {userStatuses
                  .filter((userStatus) => userStatus.status === status)
                  .map((userStatus) => (
                    <User
                      key={userStatus.user.id}
                      user={userStatus.user}
                      useCase="internal nolink"
                    />
                  ))}
              </div>
            )}
        </div>
      ))}
      <svg
        className="user-statuses-lines"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <line
          x1="25"
          y1="75"
          x2="75"
          y2="25"
          stroke="var(--warning-color)"
          strokeWidth="1"
        />
        <line
          x1="25"
          y1="75"
          x2="25"
          y2="25"
          stroke="var(--primary-color)"
          strokeWidth="1"
        />
        <line
          x1="25"
          y1="75"
          x2="75"
          y2="75"
          stroke="var(--danger-color)"
          strokeWidth="1"
        />
      </svg>
      <div className="user-statuses-summary hide-from-display">
      {statuses.map((status, index) => (
        <div
          key={index}
          className={`status-summary ${
            status ? status.replace(/\s+/g, "").toLowerCase() : "noinfo"
          }`}
        >
          <div className="status-summary-name">{status || "Brak potwierdzenia"}:</div>
          {userStatuses
            .filter((userStatus) => userStatus.status === status)
            .map((userStatus) => (
              <User
                key={userStatus.user.id}
                user={userStatus.user}
                useCase="internal nolink"
              />
            ))}
        </div>
      ))}
    </div>
    </div>
  );
};

export default UserStatuses;
