import React from "react";
import SingDaySong from "components/SingDaySong";
import "./SingDaySongViewList.css";

const SingDaySongViewList = ({ day }) => {
  if (day.songs && day.songs.length > 0) {
    const sortedSongs = [...day.songs].sort((a, b) => a.order - b.order);
    
    return sortedSongs.map((singDaySong) => (
      <SingDaySong key={singDaySong.id} singDaySong={singDaySong} />
    ));
  } else {
    return <div>Brak pieśni</div>;
  }
};

export default SingDaySongViewList;
