// src\components\songs\SongsList.js
import React, { useEffect } from "react";
import SongTitle from "../SongTitle";
import "./SongList.css";

const SongList = ({ list, refs}) => {

  useEffect(() => {
    list.forEach((song, index) => {
      if (index === 0 || song.firstLetter !== list[index - 1]?.firstLetter) {
        refs.current[song.firstLetter] = React.createRef();
      }
      refs.current[song.id] = React.createRef();
    });
  }, [list]);
  
  return (
    <div className="song-list">
      {list.map((song, index) => (
        <div id={`songid-${song.id}`} ref={refs.current[song.id]} key={song.id} className="song-list-row">
        {index === 0 || song.firstLetter !== list[index-1].firstLetter 
          ? <div ref={refs.current[song.firstLetter]}  id={`letter-${song.firstLetter}`} className="song-letter">{song.firstLetter}<span className="anchor-offset"></span></div>
          : <div className="song-letter">&nbsp;</div> }
        <SongTitle song={song} />
      </div>
      ))}
    </div>
  );
};

export default SongList;
