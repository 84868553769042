import React from 'react';
import './ChordDiagramDisplay.css';
import ChordDiagram from 'components/ChordDiagram';
import KeyboardChordDiagram from 'components/KeyboardChordDiagram';

const ChordDiagramDisplay = ({ chordDiagrams, toggleChords }) => {
  return (
    <div className="chord-diagram-display">
      <button className="close-button" onClick={toggleChords}>
        Zamknij
      </button>
      <div className="section-title">Gitara:</div>
      <div className="chord-diagram-container">
        {chordDiagrams?.map((chordData, index) => (
          <ChordDiagram key={index} chordData={chordData} />
        ))}
      </div>
      <div className="section-title">Pianino:</div>
      <div className="chord-diagram-container">
        {chordDiagrams?.map((chordData, index) => (
          <KeyboardChordDiagram key={`keyboard-${index}`} chordData={chordData} />
        ))}
      </div>
    </div>
  );
};

export default ChordDiagramDisplay;
