import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import ApiEndpoints from "components/Api";
import './ExportDayAsCeremoniaXml.css';

const ExportDayAsCeremoniaXml = ({ day }) => {
    const exportToXml = async () => {
        const token = localStorage.getItem("authToken");
        
        const apiUrl = ApiEndpoints.SING_DAYS_XML(day.id);
        
        try {
            const response = await axios.get(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                responseType: 'blob', 
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${new Date(day.date).toISOString().slice(0, 10)}.xml`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error during XML file download', error);
        }
    };

    return (
        <div data-html2canvas-ignore="true" className="export-as-xml-button-wrapper">
            <button
                className="export-as-xml-button transparent-button"
                onClick={exportToXml}
            >
                <FontAwesomeIcon icon={faDownload} />
            </button>
        </div>
    );
};

export default ExportDayAsCeremoniaXml;
