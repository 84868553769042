import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ApiEndpoints from "components/Api";
import useMessageBox from "components/MessageBox";
import "./SongContentEdit.css";

const SongContentEdit = ({ initialSong }) => {
  const [textareaHeight, setTextareaHeight] = useState("auto");
  const [song, setSong] = useState({ name: "", content: "", chords: "" });
  const [showMessage, MessageBoxComponent] = useMessageBox();

  const contentRef = useRef(null);
  const chordsRef = useRef(null);

  const normalizeSong = (song) => {
    const normalizedSong = { ...song };
    normalizedSong.content = normalizedSong.content.replace(/\r\n/g, "\n");
    normalizedSong.chords = normalizedSong.chords.replace(/\r\n/g, "\n");
    return normalizedSong;
  };

  const prepareSongForSubmission = (song) => {
    const preparedSong = { ...song };
    preparedSong.content = preparedSong.content.replace(/\n/g, "\r\n");
    preparedSong.chords = preparedSong.chords.replace(/\n/g, "\r\n");
    return preparedSong;
  };

  const adjustTextareaHeight = () => {
    const contentLines = song.content.split("\n").length;
    const chordsLines = song.chords.split("\n").length;

    const maxLines = Math.max(contentLines, chordsLines);

    if (contentRef.current && chordsRef.current) {
      let lineHeight = parseFloat(
        window
          .getComputedStyle(contentRef.current)
          .getPropertyValue("line-height")
      );
      if (window.matchMedia("(orientation: portrait)").matches) {
        lineHeight = 0.875;
      } else if (isNaN(lineHeight)) {
        lineHeight = 1.25;
      }
      const maxTextAreaHeight = maxLines * lineHeight;
      setTextareaHeight(`${maxTextAreaHeight}rem`);
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [song, textareaHeight]);

  useEffect(() => {
    setSong(normalizeSong(initialSong));
  }, [initialSong]);
  const handleContentReset = (event) => {
    event.preventDefault();
    setSong(normalizeSong(initialSong));
  };
  const handleChange = (event) => {
    setSong({ ...song, [event.target.name]: event.target.value });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const token = localStorage.getItem("authToken");
      const songCopy = prepareSongForSubmission(song);

      const response = await axios.put(
        ApiEndpoints.SONG_DETAIL(songCopy.id),
        songCopy,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      showMessage("Zapisano zmiany w pieśni.", "success");
    } catch (error) {
      showMessage(error.response.data.message, "error");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <MessageBoxComponent />
      <div className="song-content-edit-container">
        <div className="song-content-edit-title-container">
          <div className="song-content-edit-title">
            Tytuł:{" "}
            <input
              type="text"
              name="name"
              value={song.name}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="song-content-edit-content-container">
          <div className="song-content-edit-content-item">
            Tekst:
            <textarea
              ref={contentRef}
              name="content"
              value={song.content}
              onChange={handleChange}
              style={{ height: textareaHeight }}
            />
          </div>
          <div className="song-content-edit-content-item">
            Akordy:
            <textarea
              ref={chordsRef}
              name="chords"
              value={song.chords}
              onChange={handleChange}
              style={{ height: textareaHeight }}
            />
          </div>
        </div>
        <div className="song-content-edit-buttons-container">
          
          <div className="song-content-edit-buttons-item">
            <button
              className="primary-button"
              onClick={(e) => handleContentReset(e)}
            >
              Resetuj zawatość
            </button>
          </div>
          <div className="song-content-edit-buttons-item">
            <button className="primary-button">
              Zapisz zawartość
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SongContentEdit;
