import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import ApiEndpoints from "components/Api";
import User from "components/User";
import UserStatuses from "components/UserStatuses";
import UserStatusButton from "components/UserStatusButton";
import DayManagementOwnerButton from "components/DayManagementOwnerButton";
import ExportAsPngToClipboardButton from "components/ExportAsPngToClipboardButton";
import DayManagementEditModeButton from "components/DayManagementEditModeButton";
import SingDaySongViewList from "components/SingDaySongViewList";
import SingDaySongEditList from "components/SingDaySongEditList";
import "./DayManagement.css";
import SongToWordButton from "components/SongToWordButton";
import DayTexts from "components/DayTexts";
import ExportDayAsCeremoniaXml from "components/ExportDayAsCeremoniaXml";
import CommentList from "components/CommentList";

const DayManagement = ({ day: initialDay, refs }) => {
  const [day, setDay] = useState(initialDay);
  const [showUserStatuses, setShowUserStatuses] = useState(false);
  const targetRef = useRef(null);
  const [showComments, setShowComments] = useState(false);
  const [editMode, setEditMode] = useState(
    JSON.parse(localStorage.getItem(`singday-${day?.id}-editMode`) || "false")
  );

  const userId = localStorage.getItem("userId");
  const toggleComments = () => setShowComments(!showComments);
  const getUserStatus = () => {
    const userStatus = day.userStatuses.find(
      (userStatus) => userStatus.user.id.toString() === userId
    );
    if (userStatus) {
      return userStatus.status;
    } else {
      return "null";
    }
  };
  const actualUserStatus = getUserStatus();
  const updateSingDay = (newDay) => {
    setDay(newDay);
  };

  const formatedDate = new Date(day.date).toLocaleDateString("pl-PL");
  const formatedTime = new Date(day.date)
    .toLocaleTimeString("pl-PL")
    .slice(0, -3);

  const renderSongs = () => {
    if (!editMode) {
      return <SingDaySongViewList day={day} />;
    } else {
      return renderSongsEdit();
    }
  };
  const renderSongsEdit = () => {
    return <SingDaySongEditList day={day} updateSingDay={updateSingDay}/>;
  };
  
  const renderOrganizers = () => {
    if (day.owners && day.owners.length > 0) {
      return day.owners.map((owner) => (
        <User key={owner.id} user={owner} useCase="internal" />
      ));
    }
  };
  const renderOrganizersTitle = () => {
    if (day.owners && day.owners.length <= 1) {
      return "Pieśni układa: ";
    } else if (day.owners && day.owners.length > 1) {
      return "Pieśni układają: ";
    }
  };
  const renderPsalmist = () => {
    if (day.psalmist) {
      return (
        <User key={day.psalmist.id} user={day.psalmist} useCase="internal" />
      );
    }
  };
  const renderAdorationOwners = () => {
    if (day.adorationOwners && day.adorationOwners.length > 0) {
      return day.adorationOwners.map((owner) => (
        <User key={owner.id} user={owner} useCase="internal" />
      ));
    }
  };
  const renderAdorationOwnersTitle = () => {
    if (day.adorationOwners && day.adorationOwners.length > 1) {
      return "Adorację przygotowują: ";
    } else if (day.adorationOwners && day.adorationOwners.length <= 1) {
      return "Adorację przygotowuje: ";
    }
  };
  const isFirstSundayOfMonth = (date) => {
    return date.getDate() <= 7 && date.getDay() === 0; // Sunday is 0
  };

  const renderAdorationContainer = () => {
    if (
      (day.adorationOwners && day.adorationOwners.length > 0) ||
      isFirstSundayOfMonth(new Date(day.date))
    ) {
      return (
        <div className="day-management-adoration-container">
          <div className="day-management-adoration-header">
            {renderAdorationOwnersTitle()}
          </div>
          <div className="day-management-adoration">
            {renderAdorationOwners()}
          </div>
          <div
            data-html2canvas-ignore="true"
            className="day-management-adoration-buttons"
          >
            <DayManagementOwnerButton
              day={day}
              updateSingDay={updateSingDay}
              buttonOwnerType="adorationOwner"
            />
          </div>
        </div>
      );
    }
  };

  const renderUserStatuses = () => {
    if (day.userStatuses && day.userStatuses.length > 0) {
      return <UserStatuses userStatuses={day.userStatuses} />;
    }
  };

  const checkEditButtonVisibility = () => {
    let result = false;
    if (day.owners && day.owners.length > 0)
    result = day.owners.some((owner) => owner.id.toString() === userId);
    if (day.adorationOwners && day.adorationOwners.length > 0)
    result = (result || day.adorationOwners.some((owner) => owner.id.toString() === userId)); 
    return result;
  }
  const addComment = async (commentContent) => {
    const token = localStorage.getItem("authToken");
    const url = ApiEndpoints.SING_DAY_ADD_COMMENT(day.id);
    const commentData = { content: commentContent };
  
    try {
      const response = await axios.put(url, commentData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDay(response.data);
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };
  const deleteComment = async (commentId) => {
    const token = localStorage.getItem("authToken");
    const url = ApiEndpoints.SING_DAY_DELETE_COMMENT(day.id, commentId);
  
    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDay(response.data);
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };
  useEffect(() => {
    if (day?.id > 0) {
      setShowUserStatuses(true);
    }
  }, [day]);
  if (day.isDisabled) {
    return (
      <div className={`day-management-container day-management-container-disabled`}>
        <div className="day-management-date">
          {formatedDate} {formatedTime}
        </div>
        <div className="day-management-description">
          <a href={day.readingLink} target="_blank" rel="noopener noreferrer">
            {day.description}
          </a>
        </div>
        <div className="day-management-disabled-message">
          W tym dniu nie śpiewamy!
        </div>
        <div />
      </div>
    );
  }
  if (showComments)
  {
    return  (
      <div className={`day-management-container day-management-container-disabled`}>
        <div className="day-management-date">
          {formatedDate} {formatedTime}
        </div>
        <div className="day-management-description">
          <a href={day.readingLink} target="_blank" rel="noopener noreferrer">
            {day.description}
          </a>
        </div>
        <CommentList comments={day.comments} onDelete={deleteComment} onAdd={addComment}/>
        <button onClick={toggleComments} className="day-management-toggle-comments-button">
            Schowaj komentarze
          </button>
          <div />
      </div>
    )
  }
  return (
    <div
      id={"singday-" + day?.id}
      ref={el => {
        refs.current[day?.id] = el;
        targetRef.current = el;
      }}
      
      className={`day-management-container ${
        editMode
          ? "day-management-container-edit-mode-on"
          : "day-management-container-edit-mode-off"
      }`}
    > 
      <div className="day-management-header">
        <DayManagementEditModeButton
          isVisible={checkEditButtonVisibility()}
          editMode={editMode}
          day={day}
          setEditMode={setEditMode}
        />
        <ExportAsPngToClipboardButton targetRef={targetRef} />
        <ExportDayAsCeremoniaXml day={day} />
        <SongToWordButton songs={day.songs} />
        <div className="day-management-date">
          {formatedDate} {formatedTime}
        </div>
        <div className="day-management-description">
          <a href={day.readingLink} target="_blank" rel="noopener noreferrer">
            {" "}
            {day.description}
          </a>
        </div>
        <div className="day-management-location">{day.location}</div>
      </div>
      <div className="day-management-owners-container">
        <div className="day-management-organizers-container">
          <div className="day-management-organizers-header">
            {renderOrganizersTitle()}
          </div>
          <div className="day-management-organizers">{renderOrganizers()}</div>
          <div
            data-html2canvas-ignore="true"
            className="day-management-organizers-buttons"
          >
            <DayManagementOwnerButton
              day={day}
              updateSingDay={updateSingDay}
              buttonOwnerType="owner"
            />
          </div>
        </div>
        <div className="day-management-psalmist-container">
          <div className="day-management-psalmist-header">Psalm śpiewa: </div>
          <div className="day-management-psalmist">{renderPsalmist()}</div>
          <div
            data-html2canvas-ignore="true"
            className="day-management-psalmist-buttons"
          >
            <DayManagementOwnerButton
              day={day}
              updateSingDay={updateSingDay}
              buttonOwnerType="psalmist"
            />
          </div>
        </div>
        {renderAdorationContainer()}
      </div>
      <div className="day-management-songs">{renderSongs()}</div>
      <DayTexts acclamation={day.acclamation} psalmRefrain={day.psalmRefrain} />      
      {showUserStatuses && (
        <div className="day-management-people-presence-container">
          <div className="day-management-people-header">Obecności:</div>
          <div className="day-management-people">{renderUserStatuses()}</div>
        </div>
      )}
      <div className="flex-spacer"></div>
      
      {showUserStatuses && (
        <div data-html2canvas-ignore="true" className="day-management-userstatus-buttons-container">
          <div className="day-management-button-userstatus-container">
            <UserStatusButton
              singDayId={day.id}
              updateSingDay={updateSingDay}
              actualUserStatus={actualUserStatus}
              buttonUserStatus="Obecność"
            />
            <UserStatusButton
              singDayId={day.id}
              updateSingDay={updateSingDay}
              actualUserStatus={actualUserStatus}
              buttonUserStatus="Spóźnienie"
            />
            <UserStatusButton
              singDayId={day.id}
              updateSingDay={updateSingDay}
              actualUserStatus={actualUserStatus}
              buttonUserStatus="Nieobecność"
            />
          </div>
        </div>
      )}
            <button onClick={toggleComments} className="day-management-toggle-comments-button">
            Pokaż komentarze ({day.comments.length})
          </button>
      <div className="flex-spacer-nogrow"></div>

    </div>
  );
};

export default DayManagement;
