import { useState, useEffect } from "react";

// This is your custom hook
export function useSongStorage() {
  function getSingDayLookupStorageKey() {
    return `singDayLookup`;
  }
  function getOrderLookupStorageKey() {
    return `orderLookup`;
  }
  function getPartLookupStorageKey() {
    return `partLookup`;
  }

  function setSongLookup(singDay, part, order) {
    localStorage.setItem(
      getSingDayLookupStorageKey(),
      JSON.stringify({
        id: singDay.id,
        date: singDay.date,
        description: singDay.description,
      })
    );
    localStorage.setItem(getOrderLookupStorageKey(), JSON.stringify(order));
    localStorage.setItem(
      getPartLookupStorageKey(),
      JSON.stringify({
        id: part.id,
        name: part.name,
        defaultOrder: part.defaultOrder,
      })
    );
  }

  function getSingDayLookupValue() {
    const item = localStorage.getItem(getSingDayLookupStorageKey());
    return item ? JSON.parse(item) : null;
  }
  
  function getOrderLookupValue() {
    const item = localStorage.getItem(getOrderLookupStorageKey());
    return item ? JSON.parse(item) : null;
  }
  
  function getPartLookupValue() {
    const item = localStorage.getItem(getPartLookupStorageKey());
    return item ? JSON.parse(item) : null;
  }
  function getSongLookupValues() {
    return {
      singDay: getSingDayLookupValue(),
      part: getPartLookupValue(),
      order: getOrderLookupValue(),
    };
  }
  function cleanUpSongLookup() {
    localStorage.removeItem(getSingDayLookupStorageKey());
    localStorage.removeItem(getOrderLookupStorageKey());
    localStorage.removeItem(getPartLookupStorageKey());
  }

  return {
    setSongLookup,
    getSongLookupValues,
    cleanUpSongLookup,
  };
}
