import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './Comment.css'; // CSS file for styling

const Comment = ({ comment, onDelete }) => {
    const userId = localStorage.getItem("userId");
    const isAuthor = comment.author.id.toString() === userId;
  return (
    <div className="comment">
      <div className="comment-header">
        <span className="comment-author">{comment.author.login}</span>
        {isAuthor && (
        <button className="comment-delete-button" onClick={() => onDelete(comment.id)}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
        )}
      </div>
      <div className="comment-date">{new Date(comment.date).toLocaleString()}</div>
      <div className="comment-content">{comment.content}</div>
    </div>
  );
};

export default Comment;
