import React, { useState, useEffect } from "react";
import "./SongFilterBox.css";

const SongFilterBox = ({ songs, setFilteredSongs, className, placeholder }) => {
  const initialSearch = localStorage.getItem("SongFilterBoxSearch") || "";
  const [search, setSearch] = useState(initialSearch);
  const handleClear = () => {
    setSearch("");
  };
  useEffect(() => {
    const filterSongs = () => {
      if (search === "") {
        setFilteredSongs(songs);
      } else {
        const searchTerms = search.toLowerCase().split(" ");

        const filtered = songs.filter((song) => {
          return searchTerms.every((term) => {
            const inName = song.name.toLowerCase().includes(term);
            const inContent = song.content.toLowerCase().includes(term);
            const inTags = song.tags.some((tag) =>
              tag.name.toLowerCase().includes(term)
            );
            return inName || inContent || inTags;
          });
        });
        setFilteredSongs(filtered);
      }
    };

    localStorage.setItem("SongFilterBoxSearch", search);

    filterSongs();
  }, [search, songs, setFilteredSongs]);

  return (
    <div className="search-box-container">
      <div className="search-box">
        <div></div>
        <i className="material-icons search-icon">search</i>
        <input
          className="search-input"
          type="text"
          placeholder={placeholder}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {search && (
          <i className="material-icons clear-icon" onClick={handleClear}>
            close
          </i>
        )}
        <div></div>
      </div>
    </div>
  );
};

export default SongFilterBox;
