// src\components\songs\SongListPage.js
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ApiEndpoints from "components/Api";
import SongList from "components/SongList/SongList";
import SongFilterBox from "components/SongFilterBox/SongFilterBox";
import TopScroll from "components/TopScroll";
import useLoading from "components/Loading";
import SingDaySongPicker from "components/SingDaySongPicker";
import "./SongListPage.css";

const SongListPage = () => {
  const [songs, setSongs] = useState([]);
  const [filteredSongs, setFilteredSongs] = useState([]);
  const [letters, setLetters] = useState([]);
  const [showLoading, hideLoading, Loading] = useLoading();
  const navigate = useNavigate();
  const refs = useRef({});
  useEffect(() => {
    const fetchSongs = async () => {
      const token = localStorage.getItem("authToken");
      showLoading();
      const response = await axios.get(ApiEndpoints.SONGS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const sortedSongs = [...response.data].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      const songsWithFirstLetter = sortedSongs.map((song) => {
        return { ...song, firstLetter: song.name[0].toUpperCase() };
      });
      setSongs(songsWithFirstLetter);

      const distinctLetters = [
        ...new Set(songsWithFirstLetter.map((song) => song.firstLetter)),
      ];
      setLetters(distinctLetters);
      hideLoading();
    };

    fetchSongs();
  }, [navigate]);

  useEffect(() => {
    let scrollId = localStorage.getItem("scrollId");
    setTimeout(() => {
      if (refs.current[scrollId] && refs.current[scrollId].current) {
        const baseFontSize = parseFloat(
          getComputedStyle(document.documentElement).fontSize
        );
        const headerOffsetInRem = window.matchMedia("(orientation: portrait)")
          .matches
          ? 0
          : 5;
        const headerOffsetInPx = baseFontSize * headerOffsetInRem;

        const position =
          refs.current[scrollId].current.offsetTop - headerOffsetInPx;

        window.scrollTo({
          top: position,
          left: 0,
          behavior: "instant",
        });
        localStorage.removeItem("scrollId");
        scrollId = null; // clear the scrollId after scrolling
      }
    }, 50);
  });

  useEffect(() => {
    const distinctFilteredLetters = [
      ...new Set(filteredSongs.map((song) => song.firstLetter)),
    ];
    setLetters(distinctFilteredLetters);
  }, [filteredSongs]);

  const handleClick = (letter) => {
    const baseFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    const headerOffsetInRem = window.matchMedia("(orientation: portrait)")
      .matches
      ? 0
      : 5;
    const headerOffsetInPx = baseFontSize * headerOffsetInRem;

    const position = refs.current[letter].current.offsetTop - headerOffsetInPx;

    window.scrollTo({
      top: position,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="song-list-page-container">
      <Loading />
      <TopScroll />
      <SongFilterBox
        placeholder="Filtruj"
        songs={songs}
        setFilteredSongs={setFilteredSongs}
      />
      <SingDaySongPicker />

      <SongList refs={refs} list={filteredSongs} />
      <div className="letter-navigation-container">
        <div className="letter-navigation">
          <div className="letter-space-box">&nbsp;</div>
          {letters.map((letter) => (
            <a key={letter} onClick={() => handleClick(letter)}>
              {letter}
            </a>
          ))}
          <div className="letter-space-box">&nbsp;</div>
        </div>
      </div>
    </div>
  );
};

export default SongListPage;
