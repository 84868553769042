// src\components\songs\TagEdit.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import ApiEndpoints from "components/Api";
import EditableTag from "components/EditableTag";
import useMessageBox from "components/MessageBox";
import { v4 as uuidv4 } from "uuid";
import "./TagEdit.css";

const TagEdit = ({ initialTags, songId }) => {
  const [tags, setTags] = useState(initialTags);
  const [newTag, setNewTag] = useState("");
  const [showMessage, MessageBoxComponent] = useMessageBox();

  useEffect(() => {
    setTags(initialTags);
  }, [initialTags]);

  const handleTagRemove = (tagName) => {
    const newTags = tags.filter(
      (tag) => tag.name.toLowerCase() !== tagName.toLowerCase()
    );
    setTags(newTags);
  };

  const handleTagReset = (event) => {
    event.preventDefault();
    setTags([...initialTags]);
  };

  const handleTagCreate = (tagName) => {
    const newTag = { id: uuidv4(), name: tagName };
    if (!tags.find((tag) => tag.name.toLowerCase() === tagName.toLowerCase())) {
      const updatedTags = [...tags, newTag];
      setTags(updatedTags);
    }
  };

  const handleTagInputSubmit = (event) => {
    event.preventDefault();
    if (newTag.trim() !== "") {
      handleTagCreate(newTag.trim());
      setNewTag("");
    }
  };

  const handleTagSubmit = async (event) => {
    event.preventDefault();

    try {
      const token = localStorage.getItem("authToken");

      const tagRequests = tags.map((tag) => {
        const uuidv4Regex =
          /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
        const id = uuidv4Regex.test(tag.id) ? 0 : tag.id;
        return { id: id, name: tag.name };
      });

      const response = await axios.put(
        ApiEndpoints.SONG_EDIT_TAGS,
        { songId, tagRequests },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const updatedTags = response.data.tags;
      setTags(updatedTags);

      showMessage("Zapisano zmiany w tagach pieśni.", "success");
    } catch (error) {
      showMessage(error.response.data.message, "error");
    }
  };

  return (
    <form className="tag-edit-form" onSubmit={handleTagSubmit}>
      <MessageBoxComponent />
      <div className="tag-edit-container">
        <div className="tag-edit-current-tags">
          <div>Istniejące tagi: </div>
          <div className="tag-edit-tags-container">
            {tags.map((tag) => (
              <EditableTag
                key={tag.id}
                tagName={tag.name}
                isEditable={false}
                onRemove={() => handleTagRemove(tag.name)}
              />
            ))}
          </div>
        </div>
        <div className="tag-edit-input-container">
          <div className="tag-edit-input-item">
            <div>Dodaj nowy tag:</div>
            <input
              type="text"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              placeholder="Nazwa nowego tagu"
            />
            <button className="primary-button" onClick={handleTagInputSubmit}>
              Dodaj
            </button>
          </div>
        </div>
      </div>
      <div className="tag-edit-buttons-container">
        <div className="tag-edit-button-item">
          <button
            className="primary-button"
            onClick={(e) => handleTagReset(e)}
          >
            Resetuj tagi
          </button>
        </div>
        <div className="tag-edit-button-item">
          <button className="primary-button success">
            Zapisz tagi
          </button>
        </div>
      </div>
    </form>
  );
};

export default TagEdit;
