import React from 'react';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from 'components/AuthContext';
import BurgerMenu from 'components/BurgerMenu';
import Logo from 'components/Logo';
import LogoutButton from 'components/LogoutButton';
import AppPaths from 'components/AppPaths';
import './Menu.css';

const Menu = () => {
    const { isAuthorized, groupsid } = useContext(AuthContext);

    let isUserAdmin = isAuthorized && (groupsid >= 4);
    let isClemensianum = isAuthorized && (groupsid >= 2);

    const menuItems = [
        isClemensianum && { path: AppPaths.SONG_LIST, label: 'Lista', parentElement: null },
        isClemensianum && { path: AppPaths.SONG_ADD, label: 'Dodaj', parentElement: null },
        isClemensianum && { path: AppPaths.SING_DAYS, label: 'Dwudziestki', parentElement: null },
        isUserAdmin && { label: 'Admin', parentElement: null },
        isUserAdmin && { path: AppPaths.ADMIN_SING_DAYS, label: 'Dwudziestki', parentElement: 'Admin' },
        isUserAdmin && { path: AppPaths.ADMIN_USER_PRESENCE, label: 'Nieobecności', parentElement: 'Admin' },
    ].filter(Boolean);

    return (
        <>
            <div className="menu">
                <div className="menu-container">
                    <div className="menu-logo"><Logo /></div>
                    <div className='menu-elements-container'>
                        {menuItems.filter(item => !item.parentElement).map((item, index) => (
                            <div className="menuElement" key={index}>
                                {item.path ? <Link to={item.path}>{item.label}</Link> : item.label}
                                <div className="dropdown">
                                    {menuItems.filter(subItem => subItem.parentElement === item.label).map((subItem, subIndex) => (
                                        <Link key={subIndex} to={subItem.path}>{subItem.label}</Link>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="logout">
                        <LogoutButton />
                    </div>
                </div>
            </div>
            <BurgerMenu menuItems={menuItems} />
        </>
    );
};

export default Menu;
