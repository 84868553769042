import React from "react";
import Score from "components/Score";
import "./SongScores.css";

const SongScores = ({ xmlUrl, songName, transposeVal, onClose }) => {
  if (!xmlUrl || xmlUrl.length === 0) {
    return null;
  }
  return (

      <div className="song-scores-container">
        <button className="close-button" onClick={onClose}>
          Zamknij
        </button>
        <div className="score-content">
          <Score
            xmlUrl={xmlUrl}
            songName={songName}
            transposeVal={transposeVal}
          />
        </div>
      </div>

  );
};

export default SongScores;
