import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiEndpoints from "components/Api";
import TagEdit from "components/TagEdit";
import "./SongEditPage.css";
import VideoEdit from "components/VideoEdit";
import SongContentEdit from "components/SongContentEdit";
import ScoreEdit from "components/ScoreEdit";
import useLoading from "components/Loading";
import AppPaths from "components/AppPaths";

const SongEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showLoading, hideLoading, Loading] = useLoading();

  const [initialSong, setInitialSong] = useState({ name: "", content: "", chords: "" });
  const [initialTags, setInitialTags] = useState([]);
  const [initialVideos, setInitialVideos] = useState([]);

  useEffect(() => {
    const fetchSong = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const expires = new Date(localStorage.getItem("expires"));

        if (!token || !expires || expires < new Date()) {
          localStorage.removeItem("authToken");
          localStorage.removeItem("expires");
          navigate(AppPaths.LOGIN);
          return;
        }
        const response = await axios.get(ApiEndpoints.SONG_DETAIL(id), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setInitialSong(response.data);
        setInitialTags(response.data.tags);
        setInitialVideos(response.data.videos);
        localStorage.setItem('scrollId', response.data.id);
      } catch (error) {
        console.log(error);
        navigate(AppPaths.SONG_LIST);
      }
    };
    showLoading();
    fetchSong();
    hideLoading();

  }, [navigate, id]);

  return (
    <div className="song-edit">
      <Loading />
      <SongContentEdit initialSong={initialSong} />
      <TagEdit initialTags={initialTags} songId={id} />
      <VideoEdit initialVideos={initialVideos} songId={id} />
      <ScoreEdit initialScores={initialSong.scores} songId={id} />
      
      <div className="song-edit-buttons-container">
        <div className="song-edit-buttons-item">
            <button
              className="primary-button"
              onClick={() => navigate(AppPaths.SONG_WITH_ID(id))}
            >
              Powrót
            </button>
            </div>
          </div>
      <div/>
    </div>
  );
};

export default SongEdit;
