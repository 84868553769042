import React from 'react';
import Keyboard from 'components/Keyboard';
import './KeyboardChordDiagram.css';

const KeyboardChordDiagram = ({ chordData }) => {

    if (!chordData || typeof chordData.tones !== 'string') {
        return null; // Return nothing if chordData or chordData.tones is not provided
      }

  const tones = chordData.tones.split(',');

  const convertSharp = tone => tone.replace('#', ' sharp');
  const convertFlatToSharp = tone => {
    const flatToSharpMap = {
      'Ab': 'G sharp',
      'Bb': 'A sharp',
      'Cb': 'B',
      'Db': 'C sharp',
      'Eb': 'D sharp',
      'Fb': 'E',
      'Gb': 'F sharp',
      'H' : 'B',
    };

    return flatToSharpMap[tone] || tone;
  };

  return (
    <div className="keyboard-chord-diagram">
      <div className="keyboard-chord-name">{chordData.name}</div>
      <div className="keyboard-container">
        <Keyboard />
        <div className="overlay">
          {tones.map((tone, index) => {
            const convertedTone = convertFlatToSharp(convertSharp(tone));
            return <div key={index} className={`dot ${convertedTone}`}></div>
          })}
        </div>
      </div>
    </div>
  );
};

export default KeyboardChordDiagram;