import React from 'react';
import { Link } from 'react-router-dom';
import img from './images/clemensianum.png';
import AppPaths from 'components/AppPaths';
import './Logo.css';

const Logo = () => {
    return (
        <div className="logo-container">
        <Link className="logo" to={AppPaths.HOME}>
            <div className='logo-text'>Śpiewnik</div>

            <img className='logo-img' src={img} alt="Clemensianum" />
        </Link>
        </div>
    );
};

export default Logo;