import React from "react";
import ReactPlayer from "react-player";
import "./SongVideos.css";

const SongVideos = ({videos}) => {
  return (
    <div className="song-videos">
      {videos.map((video, i) => {
        return (
          <div className="video" key={i}>
            <ReactPlayer 
              url={video.link}
              width="100%"
              height="100%"
              controls={true}
              config={{
                youtube: {
                  playerVars: { 
                    rel: 0,
                    modestbranding: 1,
                  }
                }
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default SongVideos;
