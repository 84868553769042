import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { AuthContext } from "components/AuthContext";
import { IsAdmin, IsClemensianum } from "components/GroupCheckComponent";
import Menu from "components/Menu";
import useRequireAuth from "components/UseRequireAuth";
import DayManagementPage from "components/Pages/DayManagementPage";
import LoginPage from "components/Pages/LoginPage";
import NotFoundPage from "components/Pages/NotFoundPage";
import SongPage from "components/Pages/SongPage";
import SongsListPage from "components/Pages/SongListPage";
import SongEditPage from "components/Pages/SongEditPage";
import SongAddPage from "components/Pages/SongAddPage";
import "./App.css";
import Logo from "components/Logo";
import AppPaths from "components/AppPaths";
import AdminDayManagementPage from "components/Admin/Pages/AdminDayManagementPage";
import ChordTestPage from "components/Pages/ChordTestPage";
import AdminUserPage from "components/Admin/AdminUserPage";

function App() {
  useRequireAuth();
  const { isAuthorized } = useContext(AuthContext);
  if (!isAuthorized) {
    return <LoginPage />;
  }

  return (
    <div className="App">
      <div className="logo-mobile">
        <Logo />
      </div>
      <Menu />
      <div className="content">
        <Routes>
          <Route path={AppPaths.HOME} element={<SongsListPage />} />
          <Route path={AppPaths.SONG_LIST} element={<SongsListPage />} />
          <Route path={AppPaths.SONG_WITH_ID(":id")} element={<SongPage />} />
          <Route path={AppPaths.CHORD_TEST} element={<ChordTestPage />} />
          {IsClemensianum() && (
            <>
              <Route
                path={AppPaths.SING_DAYS}
                element={<DayManagementPage />}
              />
              <Route
                path={AppPaths.SONG_EDIT_WITH_ID(":id")}
                element={<SongEditPage />}
              />
              <Route path={AppPaths.SONG_ADD} element={<SongAddPage />} />
            </>
          )}
          {IsAdmin() && (
            <>
            <Route
              path={AppPaths.ADMIN_SING_DAYS}
              element={<AdminDayManagementPage />}
            />
            <Route
              path={AppPaths.ADMIN_USER_PRESENCE}
              element={<AdminUserPage />}
            />
            </>
          )}
          <Route path={AppPaths.LOGIN} element={<LoginPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
