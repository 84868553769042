// src\components\songs\SongTitle.js
import React from "react";
import { Link } from "react-router-dom";
import "./SongTitle.css";
import AppPaths from "components/AppPaths";

const SongTitle = ({ song }) => {
  return (
    <Link  className="song-title" to={AppPaths.SONG_WITH_ID(song.id)} key={song.id}>
      <div className="song-name">{song.name}</div>
      <div className="song-indicators">
      <span
        className={`score-indicator ${
          song.scores.length <= 0 ? "inactive" : ""
        }`}
      />
      <span
        className={`video-indicator ${
          song.videos.length <= 0 ? "inactive" : ""
        }`}
      />
      </div>
      
    </Link>
  );
};

export default SongTitle;
