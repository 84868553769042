import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ApiEndpoints from "components/Api";
import AppPaths from "components/AppPaths";
import useLoading from "components/Loading";
import "./UserStatusButton.css";

const UserStatusButton = ({
  singDayId,
  actualUserStatus,
  buttonUserStatus,
  updateSingDay,
}) => {
  const navigate = useNavigate();
  const [showLoading, hideLoading, Loading] = useLoading();
  const buttonClick = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const expires = new Date(localStorage.getItem("expires"));

      if (!token || !expires || expires < new Date()) {
        localStorage.removeItem("authToken");
        localStorage.removeItem("expires");
        navigate(AppPaths.LOGIN);
        return;
      }
        showLoading();
      const response = await axios.put(
        ApiEndpoints.SING_DAYS_USER_STATUS,
        {
          singDayId: singDayId,
          newUserStatus: buttonUserStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      updateSingDay(response.data);
        hideLoading();
    } catch (error) {
      console.log(error);
      hideLoading();
    }
  };
  const renderUserStatus = () => {
    switch (buttonUserStatus) {
      case "Obecność":
        return "Przyjdę";
      case "Spóźnienie":
        return "Spóźnię się";
      case "Nieobecność":
        return "Nie mogę przyjść";
      default:
        return "Nie wiem";
    }
  };
  const buttonClass = () => {
    return (
      "user-status-button user-status-button-" +
      buttonUserStatus.toLowerCase() +
      (actualUserStatus === buttonUserStatus ? "-selected" : "")
    );
  };

  return (
    <>
    <Loading />
    <button data-html2canvas-ignore="true"
      className={buttonClass() + " primary-button"}
      disabled={actualUserStatus === buttonUserStatus}
      onClick={buttonClick}
    >
      {renderUserStatus()}
    </button>
    </>
    
  );
};

export default UserStatusButton;
