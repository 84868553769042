import React, {useEffect} from "react";
import AdminDayManagement from "components/Admin/AdminDayManagement";
import "./AdminDayManagementList.css";

const AdminDayManagementList = ({ singDays, refs }) => {
  return (
    <div className="day-management-list-container">
      {singDays.map((day) => (
        <AdminDayManagement refs={refs} id={`dayid-${day.id}`} key={day.id} day={day}/>
      ))}
    </div>
  );
};

export default AdminDayManagementList;
