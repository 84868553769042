import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileWord } from '@fortawesome/free-solid-svg-icons';
import './SongToWordButton.css';

const SongToWordButton = ({ songs }) => {

  const copyToClipboard = () => {
    let textToCopy = '';
    songs.forEach(songItem => {
      const { name, content, chords } = songItem.song;
      const contentLines = content.split('\r\n');
      const chordsLines = chords.split('\r\n');
      textToCopy += name + '\r\n';
      contentLines.forEach((line, index) => {
        textToCopy += line + '\t' + (chordsLines[index] || '') + '\r\n';
      });
      textToCopy += '\r\n';
    });

    // Create a text area and set the content
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  };

  return (
    <div data-html2canvas-ignore="true" className="song-to-word-button-wrapper">
      <button
        className="song-to-word-button transparent-button"
        onClick={copyToClipboard}
      >
        <FontAwesomeIcon icon={faFileWord} />
      </button>
    </div>
  );
};

export default SongToWordButton;
