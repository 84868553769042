import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './BurgerMenu.css';
import LogoutButton from 'components/LogoutButton';

const BurgerMenu = ({ menuItems }) => {
    const [isBurgerOpen, setIsBurgerOpen] = useState(false);
    
    const toggleBurger = () => {
        setIsBurgerOpen(!isBurgerOpen);
    };

    return (
        <>
        <div className="burger-button" onClick={toggleBurger}>
            <FontAwesomeIcon icon={faBars} />
        </div>
        <div className={`burger-menu ${!isBurgerOpen ? 'hidden' : ''}`}>
            <nav>
                {menuItems.filter(item => !item.parentElement).map((item, index) => (
                    <div key={index} className="burger-item">
                        {item.path ? <Link to={item.path} onClick={toggleBurger}>{item.label}</Link> : item.label}
                        <div className="burger-dropdown">
                            {menuItems.filter(subItem => subItem.parentElement === item.label).map((subItem, subIndex) => (
                                <Link key={subIndex} to={subItem.path} onClick={toggleBurger}>{subItem.label}</Link>
                            ))}
                        </div>
                    </div>
                ))}
                <LogoutButton />
            </nav>
        </div>
        </>
    );
};

export default BurgerMenu;
