import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import './DayManagementEditModeButton.css';

const DayManagementEditModeButton = ({ isVisible, editMode, day, setEditMode }) => {

  const handleOnClick = () => {
    const newEditModeState = !editMode;
    setEditMode(newEditModeState);
    localStorage.setItem(
      `singday-${day?.id}-editMode`,
      JSON.stringify(newEditModeState)
    );
  }

  if (!isVisible) return null;

  return (
    <div data-html2canvas-ignore="true" className="day-management-edit-mode-button-wrapper">
      <button
        className="day-management-edit-mode-button"
        onClick={handleOnClick}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>
    </div>
  );
};

export default DayManagementEditModeButton;
