import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from 'components/AuthContext';
import AppPaths from 'components/AppPaths';
import './LogoutButton.css';

const LogoutButton = () => {
    const navigate = useNavigate();
    const { logOut } = useContext(AuthContext);
    const handleLogout = () => {
        // remove token and other related items
        logOut();
        navigate(AppPaths.LOGIN);
    };

    return (
        <Link className="logout-button"  onClick={handleLogout}>Wyloguj</Link>
    );
};

export default LogoutButton;
