// Tag.js
import React from 'react';
import './Tag.css'; // import CSS

const Tag = ({ tagName }) => (
  <div className="tag">
    {tagName}
  </div>
);

export default Tag;
