// AuthContext.js
import React, { createContext, useState, useEffect } from "react";
import jwt_decode from 'jwt-decode';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [groupsid, setGroupsid] = useState(0);


    useEffect(() => {
        const token = localStorage.getItem('authToken');
        const tokenExpiration = new Date(localStorage.getItem('expires'));
        const now = new Date();
        if (!token || now > tokenExpiration) {
            logOut();
        } else {
            let decoded = jwt_decode(token);
            setIsAuthorized(true);
            setGroupsid(decoded['groupsid']);
        }
    }, []);

    const logIn = (token, expires) => {
        let decoded = jwt_decode(token);
        localStorage.setItem('authToken', token);
        localStorage.setItem('expires', expires);
        setIsAuthorized(true);
        setGroupsid(decoded['groupsid']);
    };

    const logOut = () => {
        localStorage.clear();
        setIsAuthorized(false);
        setGroupsid(0);
    };

    return (
        <AuthContext.Provider value={{ isAuthorized, groupsid, logIn, logOut }}>
            {children}
        </AuthContext.Provider>
    );
};
