import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiEndpoints from "components/Api";
import TransposeChords from "components/Transpose";
import SongScores from "components/SongScores";
import SongVideos from "components/SongVideos";
import AppPaths from "components/AppPaths";
import Tag from "components/Tag";
import SingDaySongPicker from "components/SingDaySongPicker";
import ChordDiagramDisplay from "components/ChordDiagramDisplay";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faMusic, faGuitar, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import "./SongPage.css";

const SongPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const getInitialTransposeVal = () => {
    const storedTransposeVal = localStorage.getItem(`transposeVal_${id}`);
    return storedTransposeVal ? parseInt(storedTransposeVal) : 0;
  };

  const [song, setSong] = useState(null);
  const [transposeVal, setTransposeVal] = useState(getInitialTransposeVal);
  const [showScores, setShowScores] = useState(false);
  const [showChords, setShowChords] = useState(false);

  useEffect(() => {
    localStorage.setItem(`transposeVal_${id}`, transposeVal);
  }, [id, transposeVal]);
  useEffect(() => {
    if (showChords) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showChords]);
  useEffect(() => {
    if (id === null || isNaN(parseInt(id)) || parseInt(id) <= 0) {
      navigate(AppPaths.SONG_LIST);
      return;
    }

    const fetchSong = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const expires = new Date(localStorage.getItem("expires"));

        if (!token || !expires || expires < new Date()) {
          localStorage.removeItem("authToken");
          localStorage.removeItem("expires");
          navigate(AppPaths.LOGIN);
          return;
        }
        const response = await axios.get(ApiEndpoints.SONG_DETAIL(id), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSong(response.data);
      } catch (error) {
        console.log(error);
        navigate(AppPaths.SONG_LIST);
      }
    };

    fetchSong();
    localStorage.setItem("scrollId", id);
  }, [navigate, id]);

  const handleDelete = async () => {
    const userConfirmation = window.confirm("Na pewno usunąć tę pieśń?");
    if (userConfirmation) {
      try {
        const token = localStorage.getItem("authToken");
        await axios.delete(ApiEndpoints.SONG_DETAIL(id), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        navigate(AppPaths.SONG_LIST);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleToggleScores = () => {
    setShowScores(!showScores);
  };
  const handleToggleChords = () => {
    setShowChords(!showChords);
  };
  const handleTransposition = (direction) => {
    if (direction === "down") {
      setTransposeVal(transposeVal - 1 >= -11 ? transposeVal - 1 : 0);
    } else {
      setTransposeVal((transposeVal + 1) % 12);
    }
  };

  const handleSongEdit = () => {
    navigate(AppPaths.SONG_EDIT_WITH_ID(id));
  };

  // Moved here to avoid re-rendering
  const songLines = song?.content?.split("\r\n");
  const chordLines = song?.chords?.split("\r\n");
  const transposedChordLines = chordLines?.map((line) =>
    TransposeChords(line, transposeVal)
  );
  const xmlFile = song?.scores?.find((s) => s.isParent)?.fileLink;
  const tags = song?.tags;
  const chordDiagrams = song?.chordDiagrams;
  return (
    <>
      {showScores && (
        <SongScores
          onClose={handleToggleScores}
          xmlUrl={xmlFile}
          songName={song.name}
          transposeVal={transposeVal}
        />
      )}
      
      {!showScores && (
        <div className="song-content">
          <div>
            <SingDaySongPicker chosenSong={song} />
          </div>

          <div className="song-top-panel">
            <div className="song-header">{song?.name}</div>
          </div>
          <div className="song-tag-panel">
            {tags?.map((tag, index) => (
              <Tag key={index} tagName={tag?.name} />
            ))}
          </div>
          <div className="song-bottom-panel">
            <div className="song-left-panel">
              <div className="song-lyrics">
                {songLines?.map((line, i) => {
                  const isLineEmpty =
                    line?.trim() === "" &&
                    (!chordLines[i] || chordLines[i]?.trim() === "");
                  const isOnlyChordLine =
                    line?.trim() === "" &&
                    chordLines[i] &&
                    chordLines[i]?.trim() !== "";
                  return (
                    <div
                      key={i}
                      className={
                        isOnlyChordLine
                          ? "only-chord-line"
                          : isLineEmpty
                          ? "empty-line"
                          : ""
                      }
                    >
                      <div className="chord-line">
                        {transposedChordLines[i]}
                      </div>
                      <div className="text-line">{line}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="song-right-panel">
              <div className="song-right-panel-container">
                <div className="song-right-panel-transpose">
                  <div className="transpose-container">
                    <button
                      className="transpose-button-left"
                      onClick={() =>
                        setTransposeVal(
                          transposeVal - 1 >= -11 ? transposeVal - 1 : 0
                        )
                      }
                    >
                      -
                    </button>
                      <div className="primary-button-mid">
                      <span className="hide-on-mobile">Transponuj</span>({transposeVal})
                      </div>
                    <button
                      className="transpose-button-right"
                      onClick={() => setTransposeVal((transposeVal + 1) % 12)}
                    >
                      +
                    </button>
                  </div>
                </div>
                {song?.scores?.length > 0 && (
                  <div className="song-right-panel-item">
                    <button
                      className="primary-button round-mobile-button"
                      onClick={handleToggleScores}
                    >
                      <span>
                      <FontAwesomeIcon icon={faMusic} />
                    </span>
                    <span className="hide-on-mobile">Nuty</span>
                    </button>
                  </div>
                )}
                <div className="song-right-panel-item">
                  <button
                    className="primary-button  round-mobile-button"
                    onClick={handleToggleChords}
                  >
                    <span>
                      <FontAwesomeIcon icon={faGuitar} />
                    </span>
                    <span className="hide-on-mobile">Pokaż akordy</span>
                  </button>
                </div>
                <div className="song-right-panel-item">
                  <button
                    className="primary-button  round-mobile-button"
                    onClick={() => navigate(AppPaths.SONG_EDIT_WITH_ID(id))}
                  >
                    <span>
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                    <span className="hide-on-mobile">Edytuj pieśń</span>
                  </button>
                </div>
                <div className="song-right-panel-item">
                  <button
                    className="primary-button  round-mobile-button"
                    onClick={() => handleDelete()}
                  >
                    <span>
                      <FontAwesomeIcon icon={faTrashCan} />
                    </span>
                    <span className="hide-on-mobile">Usuń pieśń</span>
                  </button>
                </div>
              </div>
              <div className="song-videos-panel">
                {song?.videos?.length > 0 && (
                  <SongVideos videos={song?.videos} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {showChords && (
        <ChordDiagramDisplay
          chordDiagrams={chordDiagrams}
          toggleChords={handleToggleChords}
        />
      )}
    </>
  );
};

export default SongPage;
