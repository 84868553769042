// components/NotFoundPage.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppPaths from 'components/AppPaths';

const NotFoundPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(AppPaths.HOME);
  }, [navigate]);

  return null;
};

export default NotFoundPage;
