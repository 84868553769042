import React from 'react';
import './Keyboard.css';

const Keyboard = () => {
  const keys = ['C', 'Csharp', 'D', 'Dsharp', 'E', 'F', 'Fsharp', 'G', 'Gsharp', 'A', 'Asharp', 'B'];

  return (
    <div className="keyboard">
      {keys.map((key, index) => (
        <div key={index} className={`key ${key} ${key.includes('sharp') ? 'black-key' : 'white-key'}`}></div>
      ))}
    </div>
  );
};

export default Keyboard;