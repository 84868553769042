import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SingDaySongEdit from 'components/SingDaySongEdit';
import useSongParts from 'components/useSongParts';
import './SingDaySongEditList.css';

const SingDaySongEditList = ({ day, updateSingDay }) => {
  const { dayParts, getMainParts } = useSongParts();

  const [components, setComponents] = useState([]);

  useEffect(() => {
    let mainParts = getMainParts();

    const allParts = [...new Set([...mainParts, ...dayParts])];

    allParts.sort((a, b) => a.id - b.id);

    const newComponents = [];

    allParts.forEach((part) => {
      // Find all songs that match this part
      const sortedSingDaySongs = [...day.songs].sort((a, b) => a.order - b.order);
      const matchingSingDaySongs = sortedSingDaySongs.filter(
        (singDaySong) => singDaySong.partName === part.name
      );

      if (matchingSingDaySongs.length > 0) {
        // Render a SingDaySongEdit for each matching song
        matchingSingDaySongs.forEach((singDaySong) => {
          newComponents.push({
            id: `${part.id}-${singDaySong.id}`,
            component: (
              <SingDaySongEdit
                key={`${part.id}-${singDaySong.id}`} // Unique key for each pair
                singDay={day}
                song={singDaySong.song}
                part={part}
                order={singDaySong.order}
                singDaySongId={singDaySong.id}
                updateSingDay={updateSingDay}
              />
            ),
            order: singDaySong.order,
          });
        });
      } else if (mainParts.includes(part)) {
        newComponents.push({
          id: `${part.id}`,
          component: (
            <SingDaySongEdit
              key={part.id}
              singDay={day}
              part={part}
              song={null}
              order={part.defaultOrder}
            />
          ),
          order: part.defaultOrder,
        });
      }
    });

    newComponents.sort((a, b) => a.order - b.order);

    setComponents(newComponents);
  }, [day, dayParts, updateSingDay]);

  return (
    <>
      {components.map((item, index) => (
        item.component
      ))}
      <SingDaySongEdit
        key="no-part-no-song"
        singDay={day}
        song={null}
        part={null}
        order={null}
      />
    </>
  );
};

export default SingDaySongEditList;
