import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ApiEndpoints from "components/Api";
import AppPaths from "components/AppPaths";
import useLoading from "components/Loading";
import "./AdminDayManagementOwnerButton.css";

const AdminDayManagementOwnerButton = ({ day, buttonOwnerType, updateSingDay }) => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const [showLoading, hideLoading, Loading] = useLoading();
  const getOwnerType = () => {
    switch (buttonOwnerType.toLowerCase()) {
      case "owner":
        return "ownerUserId";
      case "psalmist":
        return "psalmistUserId";
      case "adorationowner":
        return "adorationOwnerUserId";
      default:
        return "unknown";
    }
  };

  const buttonClickAdd = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const expires = new Date(localStorage.getItem("expires"));

      if (!token || !expires || expires < new Date()) {
        localStorage.removeItem("authToken");
        localStorage.removeItem("expires");
        navigate(AppPaths.LOGIN);
        return;
      }
      showLoading();
      const ownerType = getOwnerType();
      const singDayDate = day.date; 
      
      const response = await axios.put(
        ApiEndpoints.ADMIN_SING_DAYS_OWNER_UPDATE,
        {
          singDayDate,
          [ownerType]: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      updateSingDay(response.data);
      hideLoading();
    } catch (error) {
      console.log(error);
      hideLoading();
    }
  };
  const buttonClickRemove = async () => {
    try {
        const token = localStorage.getItem("authToken");
        const expires = new Date(localStorage.getItem("expires"));
  
        if (!token || !expires || expires < new Date()) {
          localStorage.removeItem("authToken");
          localStorage.removeItem("expires");
          navigate(AppPaths.LOGIN);
          return;
        }
        showLoading();
        const ownerType = getOwnerType();
        const singDayId = day.id;
        
        const response = await axios.put(
          ApiEndpoints.ADMIN_SING_DAYS_REMOVE_ITEMS,
          {
            singDayId,
            [ownerType]: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        updateSingDay(response.data);
        hideLoading();
      } catch (error) {
        console.log(error);
        hideLoading();
      }
  };
  const buttonClick = async () => {
    if (isUserMappedToButtonOwnerType()) {
      buttonClickRemove();
    } else {
      buttonClickAdd();
    }
  }

  const isUserMappedToButtonOwnerType = () => {
    switch (buttonOwnerType.toLowerCase()) {
      case "owner":
        return day?.owners?.some((owner) => owner.id.toString() === userId);
      case "psalmist":
        return day?.psalmist?.id.toString() === userId;
      case "adorationowner":
        return day?.adorationOwners?.some((owner) => owner.id.toString() === userId);
      default:
        return false;
    }
  };

  const renderButtonText = () => {
    let buttonText = "";
    switch (buttonOwnerType.toLowerCase()) {
        case "owner":
            buttonText = "Chcę układać pieśni";
            break;
        case "psalmist":
            buttonText = "Chcę śpiewać psalm";
            break;
        case "adorationowner":
            buttonText = "Chcę układać adorację";
            break;
        default:
            return "";
    }
    if (isUserMappedToButtonOwnerType()) {
      buttonText = "Usuń mnie";
    }
    return buttonText;
  };
  
  const isButtonVisible = () => {
    let isVisible = false;
    switch (buttonOwnerType.toLowerCase()) {
        case "owner":
            isVisible = true; //day?.owners?.length < 1;
            break;
        case "psalmist":
            isVisible = day?.psalmist === null;
            break;
        case "adorationowner":
            isVisible = day?.adorationOwners?.length < 1;
            break;
        default:
            return false;
    }
    return isVisible || isUserMappedToButtonOwnerType();
  };
  return (
  
    isButtonVisible() &&
    <>
    <Loading />
    <button
      className={`primary-button ${isUserMappedToButtonOwnerType() ? 'active' : ''}`}
      onClick={buttonClick}
    >
      {renderButtonText()}
    </button>
    </>
  );
};

export default AdminDayManagementOwnerButton;
