import React from 'react';
import './DayTexts.css';

function DayTexts({ acclamation, psalmRefrain }) {
    return (
        <div className="day-texts-container">
            <div className="day-texts-psalm-container">
                <div className="day-texts-psalm-label">Refren psalmu:</div>
                <div className="day-texts-psalm">{psalmRefrain}</div>
            </div>
            <div className='day-texts-acclamation-container'>
                <div className="day-texts-acclamation-label">Aklamacja:</div>
                <div className="day-texts-acclamation">{acclamation}</div>
            </div>
        </div>
    );
}

export default DayTexts;
