import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SingDaySongPicker.css";
import useSongStorage from "components/useSongStorage";
import AppPaths from "components/AppPaths";
import axios from "axios";
import ApiEndpoints from "components/Api";
import useLoading from "components/Loading";

const SingDaySongPicker = ({chosenSong}) => {
  const { getSongLookupValues, cleanUpSongLookup } = useSongStorage();
  const [showLoading, hideLoading, Loading] = useLoading();
  const songLookupValues = getSongLookupValues();
  const [showPicker, setShowPicker] = useState(false);
  const [showAddSongPanel, setShowAddSongPanel] = useState(false);
  const [dayDate, setDayDate] = useState("");
  const [dayId, setDayId] = useState(0);
  const [dayDescription, setDayDescription] = useState("");
  const [partName, setPartName] = useState("");
  const [partId, setPartId] = useState(0);
  const [order, setOrder] = useState(0);

    const navigate = useNavigate();

  const cleanUpSingDaySongLookup = () => {
    cleanUpSongLookup();
    setShowPicker(false);
    navigate(AppPaths.SING_DAYS);
  };

  const addSong = async () => {
    const token = localStorage.getItem("authToken");
    const expires = new Date(localStorage.getItem("expires"));

    if (!token || !expires || expires < new Date()) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("expires");
      navigate(AppPaths.LOGIN);
      return;
    }
    try {
      showLoading();
      const response = await axios.put(
        ApiEndpoints.SING_DAYS_ADD_SONG,
        {
          singDayId: dayId,
          songId: chosenSong.id,
          songOrderId: order,
          songPartId: partId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      hideLoading();
    } catch (error) {
      console.error("Error deleting song", error);
      hideLoading();
    }
    cleanUpSingDaySongLookup();
    navigate(AppPaths.SING_DAYS);
  }

  useEffect(() => {
    if (songLookupValues?.singDay?.id > 0) {
      setShowPicker(true);
    } else {
      setShowPicker(false);
    }
  },);

  useEffect(() => {
    if (chosenSong?.id > 0) {
      setShowAddSongPanel(true);
    } else {
      setShowAddSongPanel(false);
    }
  }, [chosenSong]);
  const formatDateToPolish = (dateString) => {
    if (!dateString) {
      return "";
    }
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Intl.DateTimeFormat('pl-PL', options).format(date);
  }
  useEffect(() => {
    setDayId(songLookupValues?.singDay?.id);
    setDayDescription(songLookupValues?.singDay?.description);
    setDayDate(formatDateToPolish(songLookupValues?.singDay?.date));
    setPartId(songLookupValues?.part?.id);
    setPartName(songLookupValues?.part?.name);
    setOrder(songLookupValues?.order);
}, [songLookupValues]);


  return (
    showPicker && (
    <div className="sing-day-song-picker-bar">
        <Loading />
     
        <div >
          <div className="sing-day-song-picker-bar_container">
            <div className="sing-day-song-picker-bar_day_info">
              <div>Szukasz pieśni na <b>{partName}</b></div><div> dla dnia <b>{dayDate}</b></div> 
               <div>({dayDescription})</div>
            </div>
            
            <div className="sing-day-song-picker-bar_song_buttons">
            {showAddSongPanel && (<button className="sing-day-song-picker-button" onClick={addSong}>
                    Dodaj pieśń "{chosenSong?.name}"
                </button> )}
          <button className="sing-day-song-picker-button" onClick={cleanUpSingDaySongLookup}>Wróć</button>

                </div>
           

          </div>
        </div>
      
    </div>
    )
  );
};

export default SingDaySongPicker;
