import React from 'react';
import Comment from 'components/Comment';
import AddComment from 'components/AddComment';
import './CommentList.css'; // CSS file for styling

const CommentList = ({ comments, onDelete, onAdd }) => {
  return (
    <div className="comment-list">
      <AddComment onAdd={onAdd} />
      {comments.map(comment => (
        <Comment key={comment.id} comment={comment} onDelete={onDelete} />
      ))}
    </div>
  );
};

export default CommentList;
