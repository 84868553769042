// EditableTag.js
import React from 'react';
import './EditableTag.css';

const EditableTag = ({ tagName, isEditable, onRemove, onChange }) => (
    <div className="editable-tag">
      {!isEditable && (
        <>
          <span>{tagName}</span>
          <span onClick={onRemove} className="editable-tag-remove-button">x</span>
        </>
      )}
      {isEditable && (
        <input 
          type="text"
          value={tagName}
          onChange={onChange}
        />
      )}
    </div>
  );

export default EditableTag;
