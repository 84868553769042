import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppPaths from "components/AppPaths";
import useSongStorage from "components/useSongStorage";
import useSongParts from "components/useSongParts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import ApiEndpoints from "components/Api";
import useLoading from "components/Loading";
import "./SingDaySongEdit.css";

const SingDaySongEdit = ({
  part,
  song,
  singDay,
  order,
  singDaySongId,
  updateSingDay,
}) => {
  const navigate = useNavigate();
  const { dayParts } = useSongParts();

  const [selectedPartId, setSelectedPartId] = useState(part?.id ?? 1);
  const { setSongLookup } = useSongStorage();
  const [showLoading, hideLoading, Loading] = useLoading();
  useEffect(() => {
    setSelectedPartId(part?.id ?? dayParts[0]?.id ?? "");
}, [part, dayParts]);


  const chooseSong = useCallback(() => {
    const partOrder = dayParts?.find(
      (p) => Number(p.id) === Number(selectedPartId)
    )?.defaultOrder;
    const finalOrder = order ?? partOrder ?? 9999;
    const part = dayParts?.find(
      (p) => Number(p?.id) === Number(selectedPartId)
    );
    setSongLookup(singDay, part, finalOrder);
    localStorage.setItem('dayScrollId', singDay.id);
    navigate(AppPaths.SONG_LIST);
  }, [setSongLookup, navigate, singDay, selectedPartId, order, dayParts]);

  const showChosenSong = !!song?.id && !!part?.id;

  const deleteSong = async () => {
    const token = localStorage.getItem("authToken");
    const expires = new Date(localStorage.getItem("expires"));

    if (!token || !expires || expires < new Date()) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("expires");
      navigate(AppPaths.LOGIN);
      return;
    }
    try {
      showLoading();
      const response = await axios.put(
        ApiEndpoints.SING_DAYS_REMOVE_ITEMS,
        {
          singDayId: singDay.id,
          singDaySongId,
          songId: song.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      updateSingDay(response.data);
      hideLoading();
    } catch (error) {
      console.error("Error deleting song", error);
      hideLoading();
    }
  };
  const selectChange = (event) => {
    let selectedValue = event.target.value;
    setSelectedPartId(selectedValue);
  };
  return (
    <div className="sing-day-song-edit-container">
      <Loading />
      {showChosenSong && (
        <>
        <div className="sing-day-song-edit-part-name">{part?.name}:</div>
          <div className="sing-day-song-edit-song-name">
            <a
              href={AppPaths.SONG_WITH_ID(song?.id)}
              target="_blank"
              rel="noopener noreferrer"
              className="sing-day-song-edit-song-name"
            >
              {song?.name}
            </a>
          </div>
          <div className="sing-day-song-edit-button-container">
          <button
            className="sing-day-song-edit-delete-song-button"
            onClick={deleteSong}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          </div>
        </>
      )}
      {!showChosenSong && (<div className="sing-day-song-edit-dropdown-container">
        <div className="sing-day-song-edit-part-name">
        <select
          value={selectedPartId}
          onChange={selectChange}
          className="sing-day-song-edit-part-dropdown"
        >
          {dayParts.map((part) => (
            <option key={part.id} value={part.id}>
              {part.name}
            </option>
          ))}
        </select></div>
        <div className="sing-day-song-edit-button-container">
          <button className="sing-day-song-edit-button" onClick={chooseSong}>
            Wybierz pieśń
          </button>
        </div>
      </div>
      )}
    </div>
  );
};

export default SingDaySongEdit;
