import React, { useEffect, useState, useRef } from 'react';
import { OpenSheetMusicDisplay, PlaybackManager, LinearTimingSource, BasicAudioPlayer, ControlPanel } from 'osmd-extended';

const Score = ({ xmlUrl, songName, transposeVal }) => {
  const osmdRef = useRef();
  const [osmd, setOsmd] = useState(null);
  const [scoreLoaded, setScoreLoaded] = useState(false);
  const [playbackManager, setPlaybackManager] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
  }, 100); // Adjust delay as needed
}, []);

  useEffect(() => {
    const osmdInstance = new OpenSheetMusicDisplay(osmdRef.current, {
      backend: "SVG",
      autoResize: true,
      drawTitle: true,
    });
    setOsmd(osmdInstance);
  }, []);

  useEffect(() => {
    if (!osmd || scoreLoaded) {
      return;
    }

    osmd.load(xmlUrl).then(() => {
      osmd.render();
      const pm = initializePlayback(osmd); // Initialize Playback after render
      setPlaybackManager(pm);
      setScoreLoaded(true);
    });
  }, [osmd, scoreLoaded, xmlUrl]); 

  useEffect(() => {
    if (playbackManager) {
      const controlPanelElement = document.querySelector('.control-panel');
      const volumeControlElement = document.querySelector('.playback-buttons');
  
      if (controlPanelElement && volumeControlElement) {
        const customControlPanelDiv = document.querySelector('.custom-control-panel');
        const customVolumeControlDiv = document.querySelector('.custom-playback-buttons');
  
        if (customControlPanelDiv && customVolumeControlDiv) {
          customControlPanelDiv.appendChild(controlPanelElement);
          customVolumeControlDiv.appendChild(volumeControlElement);
        }
      }
    }
  }, [playbackManager]);

  useEffect(() => {
    return () => {
      if (playbackManager) {
        playbackManager.pause();
      }
    };
  }, [playbackManager]);

  const initializePlayback = (osmdInstance) => {
    const playbackListener = {
      play() {},
      pause() {},
      reset() {},
      bpmChanged() {},
      volumeChanged() {},
      volumeMute() {},
      volumeUnmute() {}
    }

    const timingSource = new LinearTimingSource();
    const playbackManager = new PlaybackManager(timingSource, undefined, new BasicAudioPlayer(), undefined);
    playbackManager.DoPlayback = true;
    playbackManager.DoPreCount = false; 
    playbackManager.PreCountMeasures = 1;
    const playbackControlPanel = new ControlPanel();
    playbackControlPanel.addListener(playbackManager);
    playbackControlPanel.addListener(playbackListener);

    timingSource.reset();
    timingSource.pause();
    timingSource.Settings = osmdInstance.Sheet.playbackSettings;
    playbackManager.initialize(osmdInstance.Sheet.musicPartManager);
    playbackManager.addListener(osmdInstance.cursor);
    playbackManager.addListener(playbackControlPanel);
    playbackManager.reset();
    osmdInstance.PlaybackManager = playbackManager;
    playbackControlPanel.clearVolumeTracks();
    playbackControlPanel.addVolumeTrack(playbackManager.Metronome.Name, playbackManager.Metronome.Id, playbackManager.Metronome.Volume*100);
    for(const instrId of playbackManager.InstrumentIdMapping.keys()) {
      const instr = playbackManager.InstrumentIdMapping.getValue(instrId);
      playbackControlPanel.addVolumeTrack(instr.Name, instrId, instr.Volume * 100);
    }
    playbackControlPanel.bpmChanged(osmdInstance.Sheet.DefaultStartTempoInBpm);
    return playbackManager;
  }  
  
  return (

        <div className='score'>
          <div className='custom-playback-buttons' />
          {/* <button onClick={exportToPdf}>Export to PDF</button> */}
          <div ref={osmdRef} />
          <div className='custom-control-panel' />
        </div>
  );
};


export default Score;
