import React, { useState, useEffect } from 'react';
import ChordDiagram from 'components/ChordDiagram';
import KeyboardChordDiagram from 'components/KeyboardChordDiagram';
import axios from 'axios';

const ChordTestPage = () => {
  const [chordData, setChordData] = useState(null);
  const [selectedChord, setSelectedChord] = useState('F_maj7');

  const chordOptions = ['F', 'F_maj7', 'C_maj7', 'G_maj7', 'D_maj7', 'D', 'A', 'E', 'E_m79'];

  useEffect(() => {
    axios.get(`https://api.uberchord.com/v1/chords/${selectedChord}`)
      .then(response => {
        const data = response.data[0];
        setChordData({
          strings: data.strings,
          fingering: data.fingering,
          chordName: data.chordName,
          tones: data.tones
        });
      })
      .catch(error => {
        console.error("Error fetching data: ", error);
      });
  }, [selectedChord]);

  const handleChordChange = (e) => {
    setSelectedChord(e.target.value);
  };

  return (
    <div className="chord-test-page">
      <h1>Chord Test Page</h1>
      <label>
        Select a Chord:
        <select value={selectedChord} onChange={handleChordChange}>
          {chordOptions.map((chord, index) => (
            <option key={index} value={chord}>{chord.replace('_', ' ')}</option>
          ))}
        </select>
      </label>
      {chordData ? <ChordDiagram chordData={chordData} /> : "Loading..."}
      {chordData ? <KeyboardChordDiagram chordData={chordData} /> : ""}
    </div>
  );
};

export default ChordTestPage;
