import React from "react";
import "./User.css";

const User = ({ user, useCase = 'public' }) => {
  if (!user) {
    return null;
  }

  if (useCase === 'internal') {
    return (
      <div className="user-container">
        <a
          href={`mailto:${user.email}`}
          target="_blank"
          rel="noopener noreferrer"
          className="user-name"
        >
          {`${user.firstName} ${user.lastName}`}
        </a>
      </div>
    );
  }
  if (useCase === 'internal nolink') {
    return (
      <div className="user-container">
          <div className="user-name">
          {`${user.firstName} ${user.lastName}`}
          </div>
      </div>
    );
  }
  // Default case is 'public'
  return (
    <div className="user-container">
      <div className="user-name">
        {user.login}
      </div>
    </div>
  );
};

export default User;
