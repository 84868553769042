export function TransposeChords(chordLine, semitones) {
  if (chordLine === "") return "";
  if (semitones === 0) return chordLine;
  if (semitones < 0) 
  {
    semitones = (semitones%12) + 12;
  }
  const chords = chordLine.split(" ");

  const transposedChords = [];

  chords.forEach((chord) => {
    let match = chord.match(
      /^([A-Ga-gHh])(#|is|b|es|s)?((m\d?|maj\d?|dim\d?|aug\d?|sus\d?|add\d?|[1-9]|1[0-3])?)?$/
    );
    if (match) {
      let root = match[1];
      let accidental = getAccidental(match[2], semitones);
      // if (root === "b" && match[1] === match[0])
      // {
      //   let accidental
      // }
      let suffix = match[3] || "";
      let isLowerCase = match[1] === match[1].toLowerCase();
      let transposedRoot = transposeRoot(root, semitones, accidental, isLowerCase);

      let transposedChord = transposedRoot ? transposedRoot + suffix : "";

      transposedChords.push(transposedChord);
    } else {
      transposedChords.push(tryHandleBassLineChord(chord, semitones));
    }
  });

  return transposedChords.toString().replace(/,/g, " ");
}

function makeLowerCase(str) {
  if (str.length === 0) return str;
  return str.charAt(0).toLowerCase() + str.slice(1);
}

function tryHandleBassLineChord(chord, semitones) {
  let slashIndex = chord.indexOf("/");

  if (slashIndex === -1) {
    // This is not a slash chord. Return the chord as is.
    return chord;
  } else {
    // This is a slash chord. Split it into base and bass and transpose each.
    let base = chord.slice(0, slashIndex);
    let bass = chord.slice(slashIndex + 1);
    let transposedBase = TransposeChords(base, semitones);
    let transposedBass = TransposeChords(bass, semitones);

    // Join them back together with a slash and return.
    return `${transposedBase}/${transposedBass}`;
  }
}
function getAccidental(accidental, semitones) {
  if (accidental === "#") {
    return "sharp";
  } else if (accidental === "b") {
    return "flat";
  } else if (accidental === "is") {
    return "germanSharp";
  } else if (accidental === "es" || accidental === "s") {
    return "germanFlat";
  } else {
    if (semitones > 0) {
      return "germanSharp";
    } else {
      return "germanFlat";
    }
  }
}
function transposeRoot(root, semitones, accidental, isLowerCase) {
  let defaultAccidental = semitones > 0 ? "germanSharp" : "germanFlat";

  const notes = {
    normal: ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "H"],
    sharp: ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "H"],
    flat: ["C", "Db", "D", "Eb", "E", "F", "Gb", "G", "Ab", "A", "B", "H"],
    germanSharp: [
      "C",
      "Cis",
      "D",
      "Dis",
      "E",
      "F",
      "Fis",
      "G",
      "Gis",
      "A",
      "Ais",
      "H",
    ],
    germanFlat: [
      "C",
      "Des",
      "D",
      "Es",
      "E",
      "F",
      "Ges",
      "G",
      "As",
      "A",
      "B",
      "Ces",
    ],
  };

  let index = notes[accidental].indexOf(root.toUpperCase());
  let transposedIndex = (index + semitones) % 12;
  let result = notes[defaultAccidental][transposedIndex];
  if (isLowerCase && result) {
    return result.toLowerCase();
  }
  return result;
}
