import { AuthContext } from "components/AuthContext";
import { useContext } from "react";

export const IsGroupUser = (groupsid) => {
    const { isAuthorized, groupsid: userGroupsid } = useContext(AuthContext);
    return isAuthorized && userGroupsid >= groupsid;
}

  export const IsClemensianum = () => {
    return IsGroupUser(2);
  };
  
  export const IsModerator = () => {
    return IsGroupUser(3);
  };
  
  export const IsAdmin = () => {
    return IsGroupUser(4);
  };
  