import React from "react";

import AppPaths from "components/AppPaths";

import "./SingDaySong.css";

const SingDaySong = ({singDaySong}) => {
    if (!singDaySong) {
        return null; 
      }
      if (!singDaySong.song) {
        return null; 
      }
  return (
    <div className="sing-day-song-container">
      <div className="sing-day-song-part-name">{singDaySong.partName}:</div>
      <div className="sing-day-song-song-name">
        <a
          href={AppPaths.SONG_WITH_ID(singDaySong.song.id)}
          target="_blank"
          rel="noopener noreferrer"
          className="sing-day-song-song-name"
        >
          {singDaySong.song.name}
        </a>
      </div>
    </div>
  );
};
export default SingDaySong;
