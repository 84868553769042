import React, { useState } from 'react';
import './AddComment.css'; // CSS file for styling

const AddComment = ({ onAdd }) => {
  const [comment, setComment] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (comment.trim()) {
      onAdd(comment);
      setComment(''); // Reset comment input after submission
    }
  };

  return (
    <form className="add-comment-form" onSubmit={handleSubmit}>
      <textarea
        className="comment-input"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder="Dodaj komentarz.."
      />
      <button className="submit-comment-button" type="submit">
        Dodaj komentarz
      </button>
    </form>
  );
};

export default AddComment;
