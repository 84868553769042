// src\components\songs\SongAdd.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ApiEndpoints from "components/Api";
import AppPaths from "components/AppPaths";
import "./SongAddPage.css";

const SongAddPage = () => {
  const navigate = useNavigate();
  const [song, setSong] = useState({ name: "", content: "", chords: "" });
  const [textareaHeight, setTextareaHeight] = useState("auto");
  const contentRef = React.useRef(null);
  const chordsRef = React.useRef(null);

  const adjustTextareaHeight = () => {
    const contentLines = song.content.split("\n").length;
    const chordsLines = song.chords.split("\n").length;

    const maxLines = Math.max(contentLines, chordsLines);

    if (contentRef.current && chordsRef.current) {
      let lineHeight = parseFloat(
        window
          .getComputedStyle(contentRef.current)
          .getPropertyValue("line-height")
      );
      if (window.matchMedia("(orientation: portrait)").matches) {
        lineHeight = 0.875;
      } else if (isNaN(lineHeight)) {
        lineHeight = 1.25;
      }
      const maxTextAreaHeight = maxLines * lineHeight;

      setTextareaHeight(`${maxTextAreaHeight}rem`);
    }
  };
  useEffect(() => {
    adjustTextareaHeight();
  }, [song]);
  const handleChange = (event) => {
    setSong({ ...song, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // validation
    if (!song.name.trim() || !song.content.trim()) {
      alert("Tytuł treść pieśni nie mogą być puste!");
      return;
    }

    try {
      const token = localStorage.getItem("authToken");
      const songCopy = { ...song };
      songCopy.content = songCopy.content.replace(/\n/g, "\r\n");
      songCopy.chords = songCopy.chords.replace(/\n/g, "\r\n");

      const response = await axios.post(ApiEndpoints.SONG_ADD, songCopy, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      navigate(AppPaths.SONG_WITH_ID(response.data.id));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="song-add-container">


        <div className="song-add-title-container">
          <div className="song-add-title">
            Tytuł:{" "}
            <input
              type="text"
              name="name"
              value={song.name}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="song-add-content-container">
          <div className="song-add-content-item">
            Tekst:
            <textarea
              ref={contentRef}
              name="content"
              value={song.content}
              onChange={handleChange}
              style={{ height: textareaHeight }}
            />
          </div>
          <div className="song-add-content-item">
            Akordy:
            <textarea
              ref={chordsRef}
              name="chords"
              value={song.chords}
              onChange={handleChange}
              style={{ height: textareaHeight }}
            />
          </div>
        </div>
        <div className="song-add-buttons-container">
          <div className="song-add-buttons-item">
            <input className="primary-button" type="submit" value="Zapisz" />
          </div>
          <div className="song-add-buttons-item">
            <button
              className="primary-button"
              onClick={() => navigate(AppPaths.SONG_LIST)}
            >
              Anuluj
            </button>
          </div>
        </div>


      </div>
      <br></br>
    </form>
  );
};

export default SongAddPage;
