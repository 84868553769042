import { useEffect } from 'react';
import jwt_decode from 'jwt-decode';

export default function useRequireAuth() {
    useEffect(() => {
        const tokenExpiration = new Date(localStorage.getItem('expires'));
        const now = new Date();

        if (!localStorage.getItem('authToken') || now > tokenExpiration) {
            localStorage.removeItem('isAuthorized');
            localStorage.removeItem('expires');
            localStorage.removeItem('groupsid');
            localStorage.removeItem('userName');
            localStorage.removeItem('userId');
        } else {
            let decoded = jwt_decode(localStorage.getItem('authToken'));
            localStorage.setItem('groupsid', decoded['groupsid']);
            localStorage.setItem('userName', decoded['unique_name']);
            localStorage.setItem('userId', decoded['nameid'])
            localStorage.setItem('isAuthorized', 'true');
        }
    });
}
