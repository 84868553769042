import React from "react";
import axios from "axios";
import ApiEndpoints from "components/Api";
import useLoading from "components/Loading";

const AdminUserStatusButton = ({ userId, singDayId, buttonUserStatus, updateSingDay }) => {
  const [showLoading, hideLoading, Loading] = useLoading();

  const buttonClick = async () => {
    try {
      const token = localStorage.getItem("authToken");
      showLoading();
      const response = await axios.put(
        ApiEndpoints.ADMIN_SING_DAYS_USER_STATUS,
        {
          userId,
          singDayId,
          newUserStatus: buttonUserStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      updateSingDay(response.data);
      hideLoading();
    } catch (error) {
      console.log(error);
      hideLoading();
    }
  };

  const buttonClass = () => {
    return (
      "admin-user-status-button user-status-button-" +
      buttonUserStatus.toLowerCase())
    ;
  };

  return (
    <>
      <Loading />
      <button
        className={buttonClass() + " primary-button"}
        onClick={buttonClick}
      >
        {buttonUserStatus}
      </button>
    </>
  );
};

export default AdminUserStatusButton;
