// src\components\songs\ScoreEdit.js
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import ApiEndpoints from "components/Api";
import useMessageBox from "components/MessageBox";
import useLoading from "components/Loading";
import "./ScoreEdit.css";

const ScoreEdit = ({ initialScores, songId }) => {
  const [scores, setScores] = useState([]);
  const [newScore, setNewScore] = useState(null);
  const [fileInputKey, setFileInputKey] = useState(uuidv4());
  const [showLoading, hideLoading, Loading] = useLoading();
  const [showMessage, MessageBoxComponent] = useMessageBox();
  const inputRef = useRef(null);
  useEffect(() => {
    if (!initialScores) {
      return;
    }
    const parentScores = initialScores.filter((score) => score.isParent);
    setScores(parentScores);
  }, [initialScores]);

  const prepareFileName = (originalName, songId) => {
    const extension = originalName.slice(originalName.lastIndexOf('.'));
    const nameWithoutExtension = originalName.slice(0, originalName.lastIndexOf('.'));
    
    const validName = nameWithoutExtension.replace(/[^a-z0-9]/gi, '_');
  
    const timestamp = Date.now();
    const preparedName = `s${songId}_T${timestamp}_${validName}${extension}`;
  
    return preparedName;
  };
  const handleScoreRemove = async (event, scoreId) => {
    event.preventDefault();
    
    try {
      showLoading();
      const token = localStorage.getItem("authToken");

      const response = await axios.delete(ApiEndpoints.SONG_EDIT_SCORE, {
        data: { scoreId, songId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setScores(scores.filter((score) => score.id !== scoreId));
        showMessage("Nuty usunięte.", "success");
      }
      hideLoading();
    } catch (error) {
      showMessage(error.message, "error");
      hideLoading();
      
    }
  };

  const handleScoreCreate = async (event) => {
    event.preventDefault();

    if (!newScore) {
      showMessage("Wybierz plik, aby przesłać nuty.", "error");
      return;
    }

    const fileType = newScore.name.split(".").pop();
    if (!(fileType === "xml" || fileType === "musicxml")) {
      showMessage("Plik musy być typu .xml.", "error");
      return;
    }
    const validFileName = prepareFileName(newScore.name, songId);

    const formData = new FormData();
    formData.append("IsParent", true);
    formData.append("FileName", validFileName);
    formData.append("Order", 0);
    formData.append("SongId", songId);
    formData.append("File", newScore);

    try {
      showLoading();
      const token = localStorage.getItem("authToken");

      const response = await axios.post(
        ApiEndpoints.SONG_EDIT_SCORE,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        const newScoreResponse = response.data;
        newScoreResponse.id = newScoreResponse.scoreId;
        delete newScoreResponse.scoreId;
        setScores([...scores, newScoreResponse]);
        setFileInputKey(uuidv4()); // Reset file input
        showMessage("Nuty zapisane.", "success");
      }
      hideLoading();
    } catch (error) {
      showMessage(error.response.data.message, "error");
      hideLoading();
    }
  };

  const handleClick = () => {
    showLoading();
    const intervalId = setInterval(() => {
      if (document.activeElement !== inputRef.current) {
        hideLoading();
        clearInterval(intervalId);
      }
    }, 150);
  };
  
  const handleFileChange = (event) => {
    setNewScore(event.target.files[0]);
    hideLoading();
  };

  return (
    <form className="score-edit-form" onSubmit={handleScoreCreate}>
      <MessageBoxComponent />
      <Loading />
      <div className="score-edit-container">
        <div className="score-edit-current-scores">
          <div>Istniejące nuty:</div>
          {scores.map((score) => (
            <div key={uuidv4()} className="score-edit-item">
              <div>{score.fileName}</div>
              <div>
                <button
                  className="primary-button"
                  onClick={(event) => handleScoreRemove(event, score.id)}
                >
                  Usuń
                </button>
              </div>
            </div>
          ))}
        </div>
        {scores.length === 0 && (
          <div className="score-edit-input-container">
            <div>Dodaj nuty:</div>
            <div>
              <input
                type="file"
                accept=".xml"
                onClick={handleClick}
                onChange={handleFileChange}
                key={fileInputKey}
                ref={inputRef}
              />
              <button className="primary-button" type="submit">
                Dodaj
              </button>
            </div>
          </div>
        )}
        {scores.length > 0 && (
          <div className="score-edit-input-container">
            <div></div>
            <div>Usuń istniejące nuty aby dodać nowe.</div>
          </div>
        )}
      </div>
    </form>
  );
};

export default ScoreEdit;
