import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import ApiEndpoints from "components/Api";
import DayManagementList from "components/DayManagementList";
import moment from "moment";
import TopScroll from "components/TopScroll";
import useLoading from "components/Loading";
import "./DayManagementPage.css";

const DayManagementPage = () => {
  const [singDays, setSingDays] = useState([]);
  const [showLoading, hideLoading, Loading] = useLoading();

  const [monthsLoaded, setMonthsLoaded] = useState(1);
  const refs = useRef({});

  useEffect(() => {
    fetchDays();
  }, [monthsLoaded]);

  useEffect(() => {
    let scrollId = localStorage.getItem("dayScrollId");
    setTimeout(() => {
      if (refs.current[scrollId]) {
        const baseFontSize = parseFloat(
          getComputedStyle(document.documentElement).fontSize
        );
        const headerOffsetInRem = window.matchMedia("(orientation: portrait)")
          .matches
          ? 0
          : 3;
        const headerOffsetInPx = baseFontSize * headerOffsetInRem;

        const position =
          refs.current[scrollId].offsetTop - headerOffsetInPx;

        window.scrollTo({
          top: position,
          left: 0,
          behavior: "instant",
        });
        localStorage.removeItem("dayScrollId");
        scrollId = null;
      }
    }, 5);
  });

  const fetchDays = async () => {
    const token = localStorage.getItem("authToken");
    const startDate = moment().startOf('day').format().slice(0, -6) + 'Z';
    const endDate = moment().add(monthsLoaded, 'months').endOf('day').format().slice(0, -6) + 'Z';
    const query = `${ApiEndpoints.SING_DAYS}?startDate=${startDate}&endDate=${endDate}`;
    showLoading();
    const response = await axios.get(query, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    hideLoading();
    setSingDays(response.data);
  };

  const loadMore = () => {
    if (monthsLoaded < 6) {
      setMonthsLoaded(monthsLoaded + 1);
    }
  };
  return (
    <div className="day-management-page-container">
      <Loading />
      <TopScroll />
      <DayManagementList refs={refs} singDays={singDays} />
      {monthsLoaded < 6 && (
        <div className="day-management-button-container">
          <button className="primary-button" onClick={loadMore}>Załaduj więcej dni</button>
        </div>
      )}
      <div />
    </div>
  );
};

export default DayManagementPage;
