import React from "react";
import Fretboard from "components/Fretboard";
import "./ChordDiagram.css";

const ChordDiagram = ({ chordData }) => {
  const { strings, fingering } = chordData;
  const stringArray = strings.split(" ");
  const fingeringArray = fingering.split(" ");

  const repeatedFingers = {};

  fingeringArray.forEach((finger, index) => {
    if (!repeatedFingers[finger]) {
      repeatedFingers[finger] = [];
    }
    if (finger !== "X") {
      repeatedFingers[finger].push(index);
    }
  });

  let minFret = Math.min(
    ...stringArray.filter((f) => f !== "X" && f !== "0").map(Number)
  );

  if (minFret >= 11) minFret = 11;
  else if (minFret >= 9) minFret = 9;
  else if (minFret >= 7) minFret = 7;
  else if (minFret >= 5) minFret = 5;
  else if (minFret >= 3) minFret = 3;
  else minFret = 1;

  return (
    <div className="chord-diagram-wrapper">
      <div className="chord-diagram-chord-name">{chordData.name}</div>

      <div className="chord-diagram">
        <Fretboard startFret={minFret} />
        <svg className="marker-layer" viewBox="0 0 300 200">
          {stringArray.map((fret, index) => {
            const adjustedFret = fret - minFret + 1;
            const finger = fingeringArray[index];
            if (fret === "X") {
              return (
                <>
                  <circle
                    key={`${index}-circle-x`}
                    cx="10"
                    cy={(5 - index) * 40}
                    r="8"
                    className="marker-x"
                  />
                  <text
                    key={`${index}-text-x`}
                    x="10"
                    y={(5 - index) * 40 + 5}
                    className="marker-x-text"
                    textAnchor="middle"
                  >
                    X
                  </text>
                </>
              );
            }
            if (adjustedFret !== "X" && repeatedFingers[finger].length === 1) {
              return (
                <>
                  <circle
                    key={`${index}-circle`}
                    cx={parseInt(adjustedFret, 10) * 60 - 30}
                    cy={(5 - index) * 40}
                    r="8"
                    className="marker"
                  />
                  <text
                    key={`${index}-text`}
                    x={parseInt(adjustedFret, 10) * 60 - 30}
                    y={(5 - index) * 40 + 5}
                    className="finger-number"
                    textAnchor="middle"
                  >
                    {finger}
                  </text>
                </>
              );
            }
            return null;
          })}
          {Object.keys(repeatedFingers).map((finger) => {
            if (repeatedFingers[finger].length > 1) {
              const points = repeatedFingers[finger].map((index) => {
                const adjustedFret = stringArray[index] - minFret + 1;
                const x = parseInt(adjustedFret, 10) * 60 - 30;
                const y = (5 - index) * 40;
                return { x, y };
              });

              const pointString = points.map((p) => `${p.x},${p.y}`).join(" ");

              const midPointY =
                points.reduce((acc, p) => acc + p.y, 0) / points.length;

              return (
                <>
                  <polyline
                    key={`polyline-${finger}`}
                    points={pointString}
                    className="polyline"
                    fill="none"
                    strokeLinecap="round" 
                  />
                  <text
                    x={points[0].x}
                    y={midPointY + 5}
                    className="finger-number"
                    textAnchor="middle"
                  >
                    {finger}
                  </text>
                </>
              );
            }
            return null;
          })}
        </svg>
      </div>
    </div>
  );
};

export default ChordDiagram;
