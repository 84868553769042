import React from 'react';
import html2canvas from 'html2canvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import './ExportAsPngToClipboardButton.css';

const ExportAsPngToClipboardButton = ({ targetRef }) => {

  const exportToPng = async () => {
    // Add a class to the target element to apply specific styles
    targetRef.current.classList.add('for-export');
  
    const canvas = await html2canvas(targetRef.current);
    canvas.toBlob(async (blob) => {
      try {
        await navigator.clipboard.write([
          new ClipboardItem({
            [blob.type]: blob,
          }),
        ]);
        console.log('Image copied to clipboard');
      } catch (err) {
        console.error('Failed to copy image: ', err);
      }
    });
  
    // Remove the class from the target element to revert the styles
    targetRef.current.classList.remove('for-export');
  };

  return (
    <div data-html2canvas-ignore="true" className="export-as-png-to-clipboard-button-wrapper">
    <button
      className="export-as-png-to-clipboard-button transparent-button"
      onClick={exportToPng}
    >
      <FontAwesomeIcon icon={faImage} />
    </button>
    </div>
  );
}

export default ExportAsPngToClipboardButton;
