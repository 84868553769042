import React, { useState } from "react";
import "./MessageBox.css";

const useMessageBox = () => {
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const showMessage = (msg, type) => {
    setMessageType(type);
    setMessage(msg);
    setTimeout(() => {
      setMessageType("");
      setMessage("");
    }, 3000);
  };

  const MessageBox = () => {
    return (
      message && 
      messageType && 
      <div className={`message-box ${messageType}`}>
        <span>{message}</span>
        </div>
    );
  };

  return [showMessage, MessageBox];
};

export default useMessageBox;