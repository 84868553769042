import React from 'react';
import './Fretboard.css';

const Fretboard = ({ startFret }) => {
  // Number of frets and strings
  const frets = 5;
  const strings = 6;

  const tuning = ['E', 'H', 'G', 'D', 'A', 'E'];

  return (
    <svg className="fretboard" viewBox="0 0 300 200">
      {/* Draw Frets */}
      {Array.from({ length: frets }).map((_, i) => (
        <rect key={i} x={i * 60} y={0} width={i === 0 ? 4 : 2} height={200} className="fret" />
      ))}

      {/* Draw Strings */}
      {Array.from({ length: strings }).map((_, i) => (
        <>
        <line key={i} x1={0} y1={i * 40} x2={300} y2={i * 40} className="string" />
        <text x="-25" y={i * 40 + 5} className="string-label">{tuning[i]}</text>
        </>
      ))}
      <text x="-5" y="230" className="starting-fret">{startFret}</text>
    </svg>
  );
};


export default Fretboard;
