import { useState, useEffect } from 'react';
import axios from 'axios';
import ApiEndpoints from 'components/Api';

export function useSongParts() {
  const [dayParts, setdayParts] = useState([]);

  async function fetchAndStoreParts() {
    const token = localStorage.getItem('authToken');
    const response = await axios.get(ApiEndpoints.SING_DAYS_PARTS, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    localStorage.setItem('songParts', JSON.stringify(response.data));
    setdayParts(response.data);
  }

  useEffect(() => {
    let storedParts = localStorage.getItem('songParts');

    if (storedParts) {
      storedParts = JSON.parse(storedParts);
      setdayParts(storedParts);
    } else {
      fetchAndStoreParts();
    }
  }, []);

  function getMainParts() {
    return dayParts.filter(part => 
      ["Wejście", "Ofiarowanie", "Komunia", "Uwielbienie", "Rozesłanie"].includes(part.name)
    );
  }

  function getAdoracjaParts() {
    return Array(3).fill(dayParts.find(part => part.name === "Adoracja"));
  }

  return {
    dayParts,
    getMainParts,
    getAdoracjaParts
  };
}
